import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  jobMetrics: [],
  odometerReading: null,
  problemList: null,
  actions: null,
  towServices: null,
  towComments: null,
  otherInfo: null,
  noOfPassengers: null,
  metricAnswers: null,
  scrollPosition: 100
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ACTUAL_PROBLEM: {
      return {
        ...state,
        problemList: action.List
      };
    }
    case types.ACTION_TAKEN: {
      return {
        ...state,
        actions: action.List
      };
    }
    case types.TOW_SERVICES: {
      return {
        ...state,
        towServices: action.List
      };
    }
    case types.TOW_COMMENT: {
      return {
        ...state,
        towComments: action.comment
      };
    }
    case types.OTHER_INFO: {
      return {
        ...state,
        otherInfo: action.comment
      };
    }
    case types.JOB_METRIC_SUCCESS: {
      return {
        ...state,
        jobMetrics: action.List
      };
    }
    case types.ODOMETER_READING: {
      return {
        ...state,
        odometerReading: action.value
      };
    }
    case types.NO_OF_PASSENGERS: {
      return {
        ...state,
        noOfPassengers: action.value
      };
    }
    case types.METRIC_ANSWERS: {
      return {
        ...state,
        metricAnswers: action.jobmetrics
      };
    }
    case types.METRIC_COPY: {
      return {
        ...state,
        metricAnswers: action.List
      };
    }
    case types.RESET_ADDITIONAL_DETAILS: {
      return {
        ...state,
        jobMetrics: [],
        odometerReading: null,
        problemList: null,
        actions: null,
        towServices: null,
        towComments: null,
        otherInfo: null,
        noOfPassengers: null,
        metricAnswers: null
      };
    }
    case types.SET_SCROLL: {
      return {
        ...state,
        scrollPosition: action.pos
      };
    }
    default:
      return state;
  }
}
