module.exports = {
  configureClient: function (config) {
    // Object.defineProperties({}, config);
    for (var i in config) {
      if (config.hasOwnProperty(i)) {
        this[i] = config[i]
      }
    }
  },
};
