import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import SelectTruckPage from "./screens/SelectTruck/SelectTruck";
import SelectEquipmentPage from "./screens/SelectEquipment/SelectEquipment";
import DriverVerificationPage from "./screens/DriverVerification/DriverVerification";
import ActivateAppPage from "./screens/ActivateApp/ActivateApp";
import PreTripPage from "./screens/Pre-Trip/PreTrip";
import PoweredByPage from "./screens/Splash-PoweredBy/PoweredBy";
import DisclaimerPage from "./screens/Disclaimer/Disclaimer";
import UserPage from "./screens/User/User";
import MobileVerificationPage from "./screens/User/MobileVerification";
//import NotMePage from "./screens/User/NotMe";
import InitAppPage from "./screens/InitApp/InitApp";

class LoginShell extends Component{
    
    render(){        
        const InitApp = InitAppPage;
        const DriverVerification = DriverVerificationPage;
        const SelectTruck = SelectTruckPage;
        const SelectEquipment = SelectEquipmentPage;
        const ActivateApp = ActivateAppPage;
        const PreTrip = PreTripPage;
        const PoweredBy = PoweredByPage;
        const Disclaimer = DisclaimerPage;
        const User = UserPage;
        const MobileVerification = MobileVerificationPage;
       // const NotMe = NotMePage;

        return (
            <Switch>
                {/* <Route exact path="/" component={loginShell.ProviderVerification} /> */}
                <Route exact path="/" component={InitApp} />
                <Route exact path="/powered-by" component={PoweredBy} />
                <Route exact path="/disclaimer" component={Disclaimer} />
                <Route exact path="/activate-app" component={ActivateApp} />
                <Route exact path="/mobile-verification" component={MobileVerification} />
                <Route exact path="/user" component={User} />
                {/* <Route exact path="/not-me" component={NotMe} /> */}
                <Route exact path="/select-truck" component={SelectTruck} />
                <Route exact path="/select-equipment" component={SelectEquipment} />
                <Route exact path="/pre-trip" component={PreTrip} />
                <Route exact path="/profile" component={DriverVerification} />                
            </Switch>
        );
    }
}

export default withRouter(LoginShell);