import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import * as actions from "./Actions";
// DO not remove client
import client from "pr-utility/config/client";
import Header from "../CommonComponent/Header";
import AlertMessage from "../CommonComponent/AlertMessage";
import LocationUpdate from "../CommonComponent/LocationUpdate";
import * as userActions from "../User/Actions";
import notification from "pr-utility/utils/notification";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
import { postType } from "pr-utility/utils/postMessage";
import { scroll } from "pr-utility/utils/utils";

class PreTrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      alertData: {},
      opt: {
        one: { selected: null },
        two: { selected: null },
        three: { selected: null }
      },
      formValid: false,
      showNote: false,
      from: this.props.location.state
        ? this.props.location.state.from || ""
        : ""
    };
    this.handleBack = this.handleBack.bind(this);
    this.showAlertModal = this.showAlertModal.bind(this);
    this.moveAhead = this.moveAhead.bind(this);
  }

  componentDidMount() {
    if (!this.state.from) {
      //Do not change case of any key here which we are posting to native
      let page = "pre-trip";
      let data = {
        AppConfig: this.props.state.AppConfig,
        authorization: this.props.state.Authorization.authorization,
        DriverData: this.props.state.DriverData,
        TruckData: this.props.state.Truck
      };
      let type = postType.APP_STATE;
      notification.sendMessageToNative(page, data, type);
    }
  }

  handleBack() {
    if (this.state.from) {
      this.props.history.push({
        pathname: "/select-Equipment",
        state: {
          from: this.state.from
        }
      });
    } else if (this.props.location.state && this.props.location.state.history) {
      this.props.history.push(this.props.location.state.history);
    } else {
      this.props.history.push("/home");
    }
  }

  updateLocation = data => {
    this.props.dispatch(userActions.updateLocationSuccess(data.dto));
  };

  showAlertModal = (netWorkError, response, callback, params) => {
    let alertData;
    if (netWorkError) {
      if (response && response.code && response.code === "ECONNABORTED") {
        alertData = {
          header: <FormattedMessage id="common.timeout_header" />,
          body: <FormattedMessage id="common.timeout_message" />,
          rightText: <FormattedMessage id="common.cancel" />,
          leftOnClick: () => {
            this.setState({ showAlert: false });
            if (params && Array.isArray(params) && params.length > 1) {
              callback(...params);
            } else if (params) {
              callback(params);
            } else {
              callback();
            }
          },
          leftText: <FormattedMessage id="common.retry" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      } else {
        alertData = {
          header: "",
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.close" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      }
    } else {
      if (response) {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: response.data ? (
            response.data.error ? (
              response.data.error
            ) : (
              <FormattedMessage id="profile.error_msg" />
            )
          ) : (
            <FormattedMessage id="profile.error_msg" />
          ),
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      } else {
        alertData = {
          header: "",
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      }
    }
    this.setState({ showAlert: true, alertData: alertData });
  };

  toggleSelect(num, ans) {
    switch (num) {
      case 1:
        this.setCheckBoxState("one", ans);
        break;
      case 2:
        this.setCheckBoxState("two", ans);
        break;
      case 3:
        scroll(".content");
        this.setCheckBoxState("three", ans);
        break;
      default:
        return this.state;
    }
  }

  setCheckBoxState = (num, value) => {
    if (num === "one" || num === "two") {
      this.setState(
        prevState => ({
          opt: {
            ...prevState.opt,
            [num]: {
              selected: value
            }
          }
        }),
        () => {
          this.validateForm();
        }
      );
    } else {
      this.setState(
        prevState => ({
          opt: {
            ...prevState.opt,
            [num]: {
              selected: value
            }
          },
          showNote: !value
        }),
        () => {
          this.validateForm();
        }
      );
    }
  };

  validateForm = () => {
    if (
      this.state.opt.one.selected !== null &&
      this.state.opt.two.selected !== null &&
      this.state.opt.three.selected !== null
    ) {
      this.setState({
        formValid: true
      });
    }
  };

  moveAhead() {
    if (!this.state.opt.one.selected && this.state.opt.two.selected) {
      this.TechnicianCheckIn(() => {
        let personalConfig = this.props.state.DriverData.personalConfig || {};
        let logData = {
          type: "on_check_in",
          dto: {
            data: {
              Authorization: this.props.state.Authorization.authorization,
              DriverData: this.props.state.DriverData,
              TruckData: this.props.state.Truck,
              AppConfig: this.props.state.AppConfig,
              LocationUpdateConfig: {
                onJobLocationUpdateInterval:
                  personalConfig.onJobLocationUpdateInterval,
                idleLocationUpdateInterval:
                  personalConfig.idleLocationUpdateInterval,
                jobOfferDuration: personalConfig.jobOfferDuration,
                notMovingLocationUpdateInterval:
                  personalConfig.notMovingLocationUpdateInterval,
                distanceForOnTheWayMoving:
                  this.props.state.DriverData.networkConfig
                    .distanceForOnTheWayNotMoving || 0.2
              }
            },
            error: false,
            errorMessage: ""
          }
        };
        notification.postMessage(logData);
        this.props.dispatch(actions.preTripAnswerStatus(true));
        this.props.history.push("/home");
      });
    } else {
      this.props.dispatch(actions.preTripAnswerStatus(false));
      this.props.history.push("/disclaimer");
    }
  }
  TechnicianCheckIn = async callback => {
    let data = {
      location: {
        latitude: this.props.state.Location.latitude || -27.46794,
        longitude: this.props.state.Location.longitude || 153.02809,
        street: this.props.state.Location.street,
        city: this.props.state.Location.city,
        state: this.props.state.Location.state,
        zipCode: this.props.state.Location.zip
      },
      truckInfo: {},
      driverInfo: {
        uid: this.props.state.Authorization.authorization.entityUid,
        checkInVehicle: true
      }
    };
    // /*eslint-disable no-unused-expressions*/
    // if(this.props.state.Truck && this.props.state.Truck.selectedTruck && this.props.state.Truck.selectedTruck.status === 0) {
    //   data.driverInfo.checkInVehicle = true;
    // }

    this.props.dispatch(actions.isLoading(true));
    const response = await actions.TechnicianCheckIn(data);
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      if (response.data.data[0].driverInfo) {
        let driverData = this.props.state.DriverData.driverDetail;
        driverData.driverInfo.status = 1;
        driverData.driverInfo.reachable = "AVAILABLE";
        await this.props.dispatch(actions.DriverDetailSuccess(driverData));
        callback();
      } else {
        this.showAlertModal(false, {
          error: "An error has occurred."
        });
        sendErrorLog({
          data: {
            error: "An error has occurred on TechnicianCheckIn(pretrip) detail."
          }
        });
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.TechnicianCheckIn, callback);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  /*eslint-disable no-script-url*/
  render() {
    /*let loaded = this.props.state.AppConfig.is_loading;*/
    let page_header = <FormattedMessage id="profile.pre-trip" />;
    const ques1 = <FormattedMessage id="profile.pre-trip.q1" />;
    const ques2 = <FormattedMessage id="profile.pre-trip.q2" />;
    const ques3 = <FormattedMessage id="profile.pre-trip.q3" />;
    const note = <FormattedMessage id="profile.pre-trip.note" />;
    const note1 = <FormattedMessage id="profile.pre-trip.note1" />;
    const button = <FormattedMessage id="profile.continue" />;
    const yes = <FormattedMessage id="profile.pre-trip.yes" />;
    const no = <FormattedMessage id="profile.pre-trip.no" />;
    return (
      <div className="pg">
        <AlertMessage
          showAlert={this.state.showAlert}
          {...this.state.alertData}
        />
        <LocationUpdate updateLocationSuccess={this.updateLocation} />
        {/* <Loader loaded={!loaded} /> */}
        <Header
          title={page_header}
          left="true"
          backButton={this.handleBack}
          PubnubConnectionLoad={false}
        />
        <div className="content">
          <div className="container">
            <form className="form mb0">
              <h2 className="pgTitle mt5 mb15">{ques1}</h2>
              <div className="form-group mb30">
                <div className="radio pr40">
                  <input
                    name="answer1"
                    type="radio"
                    id="exampleYes1"
                    className={
                      this.state.opt.one.selected === false ||
                      this.state.opt.one.selected === null
                        ? ""
                        : "checked"
                    }
                  />
                  <label
                    htmlFor="exampleYes1"
                    onClick={() => {
                      this.toggleSelect(1, true);
                    }}
                  >
                    {/*<i className="fas fa-check-square" />*/}
                    <div className="icon" />
                    <span>{yes}</span>
                  </label>
                </div>
                <div className="radio">
                  <input
                    type="radio"
                    name="answer1"
                    id="exampleNo1"
                    className={
                      this.state.opt.one.selected === true ||
                      this.state.opt.one.selected === null
                        ? ""
                        : "checked"
                    }
                  />
                  <label
                    htmlFor="exampleNo1"
                    onClick={() => {
                      this.toggleSelect(1, false);
                    }}
                  >
                    {/*<i className="fas fa-check-square" />*/}
                    <div className="icon" />
                    <span>{no}</span>
                  </label>
                </div>
              </div>
              <h2 className="pgTitle mt5 mb15">{ques2}</h2>
              <div className="form-group mb30">
                <div className="radio pr40">
                  <input
                    type="radio"
                    name="answer2"
                    id="exampleYes2"
                    className={
                      this.state.opt.two.selected === false ||
                      this.state.opt.two.selected === null
                        ? ""
                        : "checked"
                    }
                  />
                  <label
                    htmlFor="exampleYes2"
                    onClick={() => {
                      this.toggleSelect(2, true);
                    }}
                  >
                    {/*<i className="fas fa-check-square" />*/}
                    <div className="icon" />
                    <span>{yes}</span>
                  </label>
                </div>
                <div className="radio">
                  <input
                    type="radio"
                    name="answer2"
                    id="exampleNo2"
                    className={
                      this.state.opt.two.selected === true ||
                      this.state.opt.two.selected === null
                        ? ""
                        : "checked"
                    }
                  />
                  <label
                    htmlFor="exampleNo2"
                    onClick={() => {
                      this.toggleSelect(2, false);
                    }}
                  >
                    {/*<i className="fas fa-check-square" />*/}
                    <div className="icon" />
                    <span>{no}</span>
                  </label>
                </div>
              </div>
              <h2 className="pgTitle mt5 mb15">{ques3}</h2>
              <div className="form-group mb20">
                <div className="radio pr40">
                  <input
                    type="radio"
                    name="answer3"
                    id="exampleYes3"
                    className={
                      this.state.opt.three.selected === false ||
                      this.state.opt.three.selected === null
                        ? ""
                        : "checked"
                    }
                  />
                  <label
                    htmlFor="exampleYes3"
                    onClick={() => {
                      this.toggleSelect(3, true);
                    }}
                  >
                    {/*<i className="fas fa-check-square" />*/}
                    <div className="icon" />
                    <span>{yes}</span>
                  </label>
                </div>
                <div className="radio">
                  <input
                    type="radio"
                    name="answer3"
                    id="exampleNo3"
                    className={
                      this.state.opt.three.selected === true ||
                      this.state.opt.three.selected === null
                        ? ""
                        : "checked"
                    }
                  />
                  <label
                    htmlFor="exampleNo3"
                    onClick={() => {
                      this.toggleSelect(3, false);
                    }}
                  >
                    {/*<i className="fas fa-check-square" />*/}
                    <div className="icon" />
                    <span>{no}</span>
                  </label>
                </div>
              </div>
              {this.state.showNote ? (
                <div className="form-group">
                  <div className="notes">
                    {note}
                    <a href={`tel:1800812777`} className="lnk">
                      1800812777
                    </a>
                    {note1}
                  </div>
                </div>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
        <footer className="bottom_fix">
          <div className="container clearfix pl-0 pr-0 text-center">
            <a
              href="javascript:void(0)"
              className={this.state.formValid ? "btn mb0" : "btn mb0 disabled"}
              onClick={this.moveAhead}
            >
              {button}
            </a>
          </div>
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreTrip);
