import * as network from "pr-utility/utils/network";
import {
    appEvent, providerAppEvent
} from "pr-utility/utils/APISignature/ProviderVerificationAPI";

const EventId = {
    JOB_OFFER_RECEIVED_VIA_PUSH: 60000,
    JOB_OFFER_RECEIVED_VIA_PUBNUB: 60001,
    JOB_OFFER_OPENED: 60002,
    JOB_OFFER_ACCEPTED: 60003,
    JOB_OFFER_DECLINED: 60004,
    JOB_OFFER_FAILED_TO_OPEN: 60200,
    JOB_OFFER_FAILED_TO_ACCEPT: 60201,
    ON_SETTINGS_PAGE: 60301,
    ON_JOB_LIST_PAGE: 60302,
    ON_JOB_HISTORY_PAGE: 60303,
    ON_ABOUT_PAGE: 60304,
    ON_PROFILE_PAGE: 60305,
    ON_JOB_DETAIL_PAGE: 60306,
    ON_VOLUME_PAGE: 60307,
    ON_ADDITIONAL_INFO_PAGE: 60308,
    SAVED_ALERT_VOLUME: 60309,
    OPEN_EXTERNAL_LINK: 60310,
    SENT_LOG_FILE: 60311,
    USER_LOGOUT: 60312,
    ON_HOME_SCREEN: 60313,
    DRIVER_CHECKOUT: 60314,
    DRIVER_AND_VEHICLE_CHECKOUT: 60315,
    VEHICLE_CHECKOUT: 60316,
    STARTED_CHECKIN: 60317,
    ON_SELECT_VEHICLE_SCREEN: 60318,
    GO_ON_BREAK: 60319,
    STOP_INCOMING_JOBS: 60320,
    BACK_FROM_BREAK_AND_AVAILABLE: 60321,
    DRIVER_CHECKIN: 60322,
    ON_STATUS_TRACKER: 60323,
    DRIVER_ON_THE_WAY: 60324,
    DRIVER_ON_SITE: 60325,
    ON_SITE_AND_START_PRE_INSPECTION: 60326,
    START_POST_INSPECTION: 60327,
    JOB_COMPLETED: 60328,
    ON_END_SERVICE: 60329,
    JOB_COMPLETE_REQUIRE_TOW: 60330,
    ON_JOB_CANCEL_PAGE: 60331,
    ON_JOB_DECLINE_PAGE: 60332,
    JOB_CANCELLED: 60333,
    ON_MOBILE_VERIFICATION: 60334,
    MOBILE_VALIDATED: 60335,
    ON_USER_PIN: 60336,
    ON_SELECT_EQUIPMENT_SCREEN: 60337,
}

const EventName = {
    60000: "JOB_OFFER_RECEIVED_VIA_PUSH",
    60001: "JOB_OFFER_RECEIVED_VIA_PUBNUB",
    60002: "JOB_OFFER_OPENED",
    60003: "JOB_OFFER_ACCEPTED",
    60004: "JOB_OFFER_DECLINED",
    60200: "JOB_OFFER_FAILED_TO_OPEN",
    60201: "JOB_OFFER_FAILED_TO_ACCEPT",
    60301: "ON_SETTINGS_PAGE",
    60302: "ON_JOB_LIST_PAGE",
    60303: "ON_JOB_HISTORY_PAGE",
    60304: "ON_ABOUT_PAGE",
    60305: "ON_PROFILE_PAGE",
    60306: "ON_JOB_DETAIL_PAGE",
    60307: "ON_VOLUME_PAGE",
    60308: "ON_ADDITIONAL_INFO_PAGE",
    60309: "SAVED_ALERT_VOLUME",
    60310: "OPEN_EXTERNAL_LINK",
    60311: "SENT_LOG_FILE",
    60312: "USER_LOGOUT",
    60313: "ON_HOME_SCREEN",
    60314: "DRIVER_CHECKOUT",
    60315: "DRIVER_AND_VEHICLE_CHECKOUT",
    60316: "VEHICLE_CHECKOUT",
    60317: "STARTED_CHECKIN",
    60318: "ON_SELECT_VEHICLE_SCREEN",
    60319: "GO_ON_BREAK",
    60320: "STOP_INCOMING_JOBS",
    60321: "BACK_FROM_BREAK_AND_AVAILABLE",
    60322: "DRIVER_CHECKIN",
    60323: "ON_STATUS_TRACKER",
    60324: "DRIVER_ON_THE_WAY",
    60325: "DRIVER_ON_SITE",
    60326: "ON_SITE_AND_START_PRE_INSPECTION",
    60327: "START_POST_INSPECTION",
    60328: "JOB_COMPLETED",
    60329: "ON_END_SERVICE",
    60330: "JOB_COMPLETE_REQUIRE_TOW",
    60331: "ON_JOB_CANCEL_PAGE",
    60332: "ON_JOB_DECLINE_PAGE",
    60333: "JOB_CANCELLED",
    60334: "ON_MOBILE_VERIFICATION",
    60335: "MOBILE_VALIDATED",
    60336: "ON_USER_PIN"
}


const EntityTypes = {
    Driver: "Driver",
    Truck: "Truck",
    Dispatch: "Dispatch",
}





/**
 * Event Log on server for each event success
 * @param jobNumber - Job number
 * @param entityId - driverId or truckid
 * @param entityType - Driver or Truck or Dispatch
 * @param eventId - job's event Id 
 * @param value - job's event name 
 * @param source
 * @param os
 * @constructor
 */

// { "jobNumber":70706, "id":60000, "value":"Job Offer Received via push", "entityId":"590e9254-f8df-4425-b7af-6006737fb93f", 
// "entityType":"Truck" }

var LogAllEvent = (jobNumber, eventId, entityId, entityType, eventName = "") => {
    eventName = EventName[eventId]
    let data = {
        "jobNumber": jobNumber,
        "entityId": entityId,
        "entityType": entityType,
        "id": eventId, //should be mapped like confluence
        "value": eventName,
        "source": "app", //Hard coded as of now
    }

    // console.log("LogAllEvent - ")
    // console.log(data)
    let endPoint;
    if (process.env.REACT_APP_COUNTRY === 'AU') {
        endPoint = appEvent();
    } else if (process.env.REACT_APP_COUNTRY === 'US') {
        endPoint = providerAppEvent();
    }
    try{
        network.POST(
            endPoint,
            data
        );
    }catch{
        
    }
    
}

export {
    LogAllEvent,
    EntityTypes,
    EventName,
    EventId,
};