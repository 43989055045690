import { combineReducers } from "redux";

import App from "./App/reducer";
import ShellAuth from "./Authorization/reducer";

import ProviderVerification from "../pr-login/src/reducers/ProviderVerification/reducer";
import UserLocation from "../pr-login/src/reducers/User/reducer";
import DriverVerification from "../pr-login/src/reducers/DriverVerification/reducer";
import Config from "../pr-login/src/reducers/Config/reducer";
import SelectTruck from "../pr-login/src/reducers/SelectTruck/reducer";
import SelectEquipment from "../pr-login/src/reducers/SelectEquipment/reducer";
import AuthorizationData from "../pr-login/src/reducers/Authorization/reducer";
import DriverDataInfo from "../pr-login/src/reducers/Driver/reducer";

import Home from "../pr-home/src/reducers/Home/reducer";
import AddPart from "../pr-home/src/reducers/AddPart/reducer";
import connectionReducer from "pr-utility/pubnub/reducer";
import Indemnity from "../pr-home/src/reducers/Indemnity/reducer";

import AdditionalDetails from "../pr-settings/src/reducers/ActualProblem/reducer";
import Settings from "../pr-settings/src/reducers/Settings/reducer";

const appReducer = combineReducers({
  Provider: ProviderVerification,
  Driver: DriverVerification,
  AppConfig: App ? App : Config,
  Truck: SelectTruck,
  Equipment: SelectEquipment,
  Home: Home,
  AddPart: AddPart,
  Settings: Settings,
  AdditionalDetails: AdditionalDetails,
  connection: connectionReducer,
  Authorization: ShellAuth ? ShellAuth : AuthorizationData,
  DriverData: DriverDataInfo,
  Location: UserLocation,
  Indemnity: Indemnity
});

export default function rootReducer(state, action) {
  return appReducer(state, action);
}
