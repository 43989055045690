import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  providerVerification: null,
  providerPhoneNumber:null
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROVIDER_VERIFICATION_SUCCESS: {
      return {
        ...state,
        providerVerification: action.response
      };
    }
    case types.PROVIDER_PHONE_NUMBER: {
      return {
        ...state,
        providerPhoneNumber: action.phoneNumber
      };
    }
    default:
      return state;
  }
}
