export const ON_ACTIVATION = "ON_ACTIVATION";
export const PROVIDER_VERIFICATION_SUCCESS = "PROVIDER_VERIFICATION_SUCCESS";
export const ON_GET_CONFIG_SUCESS = "ON_GET_CONFIG_SUCESS";
export const ON_GET_CANCEL_REASONS_SUCESS = "ON_GET_CANCEL_REASONS_SUCESS";
export const IS_LOADING = "IS_LOADING";
export const DRIVER_VERIFICATION_SUCCESS = "DRIVER_VERIFICATION_SUCCESS";
export const PROVIDER_PHONE_NUMBER = "PROVIDER_PHONE_NUMBER";
export const ON_GET_DECLINED_JOB_SUCESS = "ON_GET_DECLINED_JOB_SUCESS";
export const ON_GET_PERFORMANCE_SUCCESS = "ON_GET_PERFORMANCE_SUCCESS";
export const JOB_ID = "JOB_ID";
export const SELECTED_SERVICES = "SELECTED_SERVICES";
export const ALL_SERVICE_LIST_SUCCESS = "ALL_SERVICE_LIST_SUCCESS";
export const SELECT_TRUCK = "SELECT_TRUCK";
export const ACTUAL_PROBLEM = "ACTUAL_PROBLEM";
export const ACTION_TAKEN = "ACTION_TAKEN";
export const TOW_SERVICES = "TOW_SERVICES";
export const TOW_COMMENT = "TOW_COMMENT";
export const OTHER_INFO = "OTHER_INFO";
export const NO_OF_PASSENGERS = "NO_OF_PASSENGERS";
export const UPDATE_VOLUME_LEVEL = "UPDATE_VOLUME_LEVEL";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const JOB_METRIC_SUCCESS = "JOB_METRIC_SUCCESS";
export const ODOMETER_READING = "ODOMETER_READING";
export const RESET_ADDITIONAL_DETAILS = "RESET_ADDITIONAL_DETAILS";
export const ON_ADD_ACTIVE_JOB_LIST = "ON_ADD_ACTIVE_JOB_LIST";
export const DRIVER_DETAIL_SUCCESS = "DRIVER_DETAIL_SUCCESS";
export const VEHICLE_DETAIL_SUCCESS = "VEHICLE_DETAIL_SUCCESS";
export const RESET_ADDED_PARTS = "RESET_ADDED_PARTS";
export const METRIC_COPY = "METRIC_COPY";
export const METRIC_ANSWERS = "METRIC_ANSWERS";
export const ON_CLEAR_ACTIVE_JOB = "ON_CLEAR_ACTIVE_JOB";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const TOKEN_SENT = "TOKEN_SENT";
export const JOB_OFFER_ACCEPT = "JOB_OFFER_ACCEPT";
export const SET_EXPIRATION_TIME = "SET_EXPIRATION_TIME";
export const LAST_ACTION_PERFOMED_JOB = "LAST_ACTION_PERFOMED_JOB";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const ADD_ONE_NOTIFICATION = "ADD_ONE_NOTIFICATION";
export const SET_SCROLL = "SET_SCROLL";