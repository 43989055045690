var ENVIROMENT = "dev"; //prod or dev
var API_END_POINT = 1; // 2 for dev02 and 1 for dev01
var AppClient = "Urgently"; //Harley, Audi, Urgently

module.exports = {
  DEV_CONFIG_URL_UL: "https://dev02-configs.urgent.ly",
  CONFIG_URL_UL: "https://configs.urgent.ly",
  SENTRY_DSN: "https://833a0cbb6ee341b88196476f157b8ad6@sentry.io/1394111",
  PLATFORM_NATIVE: "ReactNative",
  PLATFORM_WEB: "Web",
  DEVICE: "",
  DEVICE_OS_VERSION: "",
  APPLICATION_TYPE: "Customer",
  APP_PARTNER: function() {
    if(process.env.REACT_APP_COUNTRY === 'AU'){
      return "Allianz Assistance";
    } else if(process.env.REACT_APP_COUNTRY === 'US'){
      return "Urgent.ly";
    }
  },
  ACCESS_TOKEN: "u9iPpKB4AArC7feZh1EEoB9zpxQz1Ixm",
  API_VERSION: "",
  INDUSTRY_TYPE: "",
  APP_VESION: "",
  APP: "",
  ENTITY_UID: "",
  ENTITY_TYPE: "",
  HOST_URL: "",
  HOST_URL_V3: "",
  DEV_END_POINTS: ["dev", "dev01", "dev02", "dev03"],
  DEV1_HOST_URL: "https://dev01-apis.urgent.ly/v4",
  DEV2_HOST_URL: "https://dev02-apis-ecs.urgent.ly/v4",
  PROD_HOST_URL: "https://dev01-apis.urgent.ly/v4",
  DEV1_AUTH_URL: "https://dev01-auth.urgent.ly",
  DEV2_AUTH_URL: "https://dev02-auth.urgent.ly",
  PROD_AUTH_URL: "https://dev01-auth.urgent.ly",
  AUTH_URL: "",
  REFRESH_URL: "",
  AUTH_CODE: "",
  AUTH_TOKEN: "",
  REFRESH_TOKEN: "",
  ENVIROMENTUL: ENVIROMENT,
  QTYPE: "DEFAULT",
  DEFAULT_COUNTRY_CODE: "US",
  PHONE_CODE: "1",
  TRUCK_ACTION: "",
  APP_CLIENT: AppClient,
  CLIENT_ID: "5",
  CONFIG_URL: "",
  COUNTRY_CODE: "",
  DRIVER_LOGIN: 1,
  ENVIROMENT_STRING: "",
  INDEMNITY_REFERRAL: true,
  DEFAULT_MAP_CENTER: function() {
    if (process.env.REACT_APP_COUNTRY === "AU") {
      return { lat: -27.470125, lng: 153.021072 };
    } else if (process.env.REACT_APP_COUNTRY === "US") {
      return { lat: 38.911063, lng: -77.3851109 };
    }
  },
  US: {
    DEV_CONFIG: "https://dev-configs.urgent.ly/configs",
    PROD_CONFIG: "https://configs.urgent.ly/configs",
    API_KEY: "u9iPpKB4AArC7feZh1EEoB9zpxQz1Ixm"
  },
  AU: {
    DEV_CONFIG: "https://dev-pm-configs.allianzau.urgent.ly/configs",
    PROD_CONFIG: "https://configs-pm.allianzau.urgent.ly/configs",
    API_KEY: "u9iPpKB4AArC7feZh1EEoB9zpxQz1Ixm"
  },
  UK: {
    DEV_CONFIG: "https://dev02-configs.allianzuk.urgent.ly/configs",
    PROD_CONFIG: "https://configs.allianzuk.urgent.ly/configs",
    API_KEY: "dd6f8706-660f-423b-8134-4d61b589d6c2"
  },
  IN: {
    DEV_CONFIG: "https://dev-configs.awpin.urgent.ly/configs",
    PROD_CONFIG: "https://configs.awpin.urgent.ly/configs",
    API_KEY: "u9iPpKB4AArC7feZh1EEoB9zpxQz1Ixm"
  },
  getDefaultHostURL() {
    var url =
      ENVIROMENT === "dev" ? this.DEV_CONFIG_URL_UL : this.CONFIG_URL_UL;
    return url;
  },
  getConfigURL() {
    var url;
    var country = this.DEFAULT_COUNTRY_CODE;
    if (ENVIROMENT === "prod") {
      url = this[country].PROD_CONFIG;
    } else {
      url = this[country].DEV_CONFIG;
    }
    return url;
  },
  getHostURL() {
    if (ENVIROMENT === "dev") {
      if (API_END_POINT === 1) {
        return this.DEV1_HOST_URL;
      } else if (API_END_POINT === 2) {
        return this.DEV2_HOST_URL;
      }
    } else if (ENVIROMENT === "prod") {
      return this.PROD_HOST_URL;
    }
  },
  getAuthURL() {
    if (ENVIROMENT === "dev") {
      if (API_END_POINT === 1) {
        this.AUTH_URL = this.DEV1_AUTH_URL;
      } else if (API_END_POINT === 2) {
        this.AUTH_URL = this.DEV2_AUTH_URL;
      }
    } else if (ENVIROMENT === "prod") {
      this.AUTH_URL = this.PROD_AUTH_URL;
    }
  },
  getRefreshURL() {
    return this.REFRESH_URL;
  },

  setAuthToken(authToken) {
    this.AUTH_TOKEN = authToken;
  },

  setConfigURL(url) {
    this.CONFIG_URL = url;
  },

  setHostURL(host_url) {
    this.HOST_URL = host_url;
  },

  setHostURLV3(host_url) {
    this.HOST_URL_V3 = host_url;
  },

  setAuthURL(url) {
    this.AUTH_URL = url;
  },

  setRefreshURL(url) {
    this.REFRESH_URL = url;
  },

  setAPIVersion(version) {
    this.API_VERSION = version;
  },

  setIndustry(industry) {
    this.INDUSTRY_TYPE = industry;
  },

  setDevice(device) {
    this.DEVICE = device;
  },

  setDeviceOsVersion(deviceOS) {
    this.DEVICE_OS_VERSION = deviceOS;
  },

  setApp(app) {
    this.APP = app;
  },

  setCountryCode(code) {
    this.COUNTRY_CODE = code;
  },

  setTruckAction(bool) {
    this.TRUCK_ACTION = bool;
  },

  setCountryISOCode(code) {
    this.DEFAULT_COUNTRY_CODE = code;
  },

  setAppVersion(appVersion) {
    this.APP_VESION = appVersion;
  },

  setEntityUid(id) {
    this.ENTITY_UID = id;
  },

  setEntityType(type) {
    this.ENTITY_TYPE = type;
  },

  setEnvirontmentString(env) {
    this.ENVIROMENT_STRING = env;
    ENVIROMENT = env;
    this.ENVIROMENTUL = env;
  }
};
