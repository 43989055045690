import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Events } from "pr-utility/event-manager/event";
import Loader from "../../../../Loader";
import { withRouter } from "react-router-dom";
import * as actions from "./Actions";
import * as indemnityActions from "pr-home/src/screens/ReferralDocket/Actions";
import client from "pr-utility/config/client";
import { reverseGeocode } from "pr-utility/utils/mapUtils";
import { EventList } from "pr-utility/event-manager/event-list";
import notification from "pr-utility/utils/notification";
import { getNextPage } from "pr-utility/utils/utils";
import { FLOW } from "../../flow";
import { FLOW_US, FLOW_US_TEST } from "../../flow_us";
import { postType } from "pr-utility/utils/postMessage";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
import { FormattedMessage } from "react-intl";
import AlertMessage from "../CommonComponent/AlertMessage";

class InitApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadView: false,
      showAlert: false,
      alertData: {}
    };
  }
  startApp = activationCode => {
    if (process.env.REACT_APP_COUNTRY === "AU") {
      this.startTimerForDisclaimer();
      //this.activateAppFlowStart(activationCode);
    } else if (process.env.REACT_APP_COUNTRY === "US") {
      //this.startActivateApp();
      this.activateAppFlowStart(activationCode);
    }
  };
  startTimerForDisclaimer = () => {
    this.props.history.push("/disclaimer");
  };
  startActivateApp = () => {
    this.props.history.push("/activate-app");
  };

  activateAppFlowStart = async activationCode => {
    await this.loadConfigs();
    await this.activateApp(activationCode, () => {
      if (
        this.props.state.Authorization.authorization.entityType === "DRIVER"
      ) {
        let nextPage;
        if (process.env.REACT_APP_COUNTRY === "US") {
          let testActivation = [
            "610890",
            "585668",
            "775781",
            "001761",
            "156963",
            "361859"
          ];
          if (testActivation.indexOf(activationCode.substr(0, 6)) >= 0) {
            nextPage = getNextPage(FLOW_US_TEST);
          } else {
            nextPage = getNextPage(FLOW_US);
          }
        } else {
          nextPage = getNextPage(FLOW);
        }
        this.props.history.push(nextPage);
      } else {
        this.truckCheckIn(() => {
          this.getTruckConfig(() => {
            this.getTruckDetail(() => {
              this.props.history.push("/home");
            });
          });
        });
      }
    });
  };

  loadConfigs = async () => {
    await this.loadConfigData();
    await this.loadConfigKeysData();
    let page = "activate-app";
    let code = null;
    if (
      this.props.state.Authorization.authorization &&
      this.props.state.Authorization.authorization.code
    ) {
      code = this.props.state.Authorization.authorization.code;
    } else if (this.state.activationCode && this.state.activationCode !== "") {
      code = this.state.activationCode;
    } else if (
      this.props.history.location.state &&
      this.props.history.location.state.code
    ) {
      code = this.props.history.location.state.code;
    }
    let data = {
      AppConfig: this.props.state.AppConfig,
      code: code
    };
    if (data.code) {
      this.setState({ activationCode: data.code, activationCodeEntered: true });
    }
    let type = postType.APP_STATE;
    notification.sendMessageToNative(page, data, type);
  };

  loadConfigData = async () => {
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.loadConfig();
    if (response && Number(response.status) === 200) {
      this.props.dispatch(actions.fetchSuccessConfig(response.data.data[0]));
      this.props.dispatch(actions.isLoading(false));
      if (
        response.data &&
        response.data.data[0] &&
        response.data.data[0].config
      ) {
        let hostUrl = response.data.data[0].config["api.url.v4"];
        let hostUrlV3 = response.data.data[0].config["api.url"];
        client.setHostURL(hostUrl);
        client.setHostURLV3(hostUrlV3);
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.loadConfigData);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  loadConfigKeysData = async () => {
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.loadConfigKeys();
    if (response && Number(response.status) === 200) {
      this.props.dispatch(
        actions.fetchSuccessConfigKeys(response.data.data[0].config)
      );
      this.props.dispatch(actions.isLoading(false));
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.loadConfigKeysData);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  activateApp = async (activationCode, callback) => {
    let data = { activationCode: activationCode };
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.activateApp(data);
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      this.setState({ activateEnabled: true });
      client.setAuthToken(response.data.accessToken);
      client.setEntityType(response.data.entityType);
      client.setEntityUid(response.data.entityUid);
      this.props.dispatch(actions.authDataSuccess(response.data));
      this.saveActivationCode(activationCode);
      const entityId = response.data.entityUid;
      const entityType = response.data.entityType;
      if (client.DRIVER_LOGIN === 1) {
        const body = {
          entityId: entityId,
          entityType: entityType
        };
        actions.activateAppSuccess(body);
      }
      callback();
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.activateApp, [
        activationCode,
        callback
      ]);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  saveActivationCode = activationCode => {
    this.props.dispatch(actions.saveActivationCode(activationCode));
  };
  showAlertModal = (netWorkError, response, callback, params) => {
    let alertData;
    if (netWorkError) {
      if (response && response.code && response.code === "ECONNABORTED") {
        alertData = {
          header: <FormattedMessage id="common.timeout_header" />,
          body: <FormattedMessage id="common.timeout_message" />,
          rightText: <FormattedMessage id="common.cancel" />,
          leftOnClick: () => {
            this.setState({ showAlert: false });
            if (params && Array.isArray(params) && params.length > 1) {
              callback(...params);
            } else if (params) {
              callback(params);
            } else {
              callback();
            }
          },
          leftText: <FormattedMessage id="common.retry" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      } else {
        alertData = {
          header: "",
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.close" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      }
    } else {
      if (response) {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: response.data ? (
            response.data.error ? (
              response.data.error
            ) : (
              <FormattedMessage id="profile.error_msg" />
            )
          ) : (
            <FormattedMessage id="profile.error_msg" />
          ),
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            if (process.env.REACT_APP_COUNTRY === "US") {
              //alert("clicked ok");
              let userData = {
                type: postType.LOGOUT,
                dto: {
                  data: {},
                  error: false,
                  errorMessage: ""
                }
              };
              notification.postMessage(userData);
            }
            this.setState({ showAlert: false });
          }
        };
      } else {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      }
    }
    this.setState({ showAlert: true, alertData: alertData });
  };

  truckCheckIn = async callback => {
    this.props.dispatch(actions.isLoading(true));
    let location = this.props.state.Location;
    let data = {
      location: {
        latitude: location.latitude,
        longitude: location.longitude,
        street: location.street,
        city: location.city,
        state: location.state,
        zipCode: location.zip
      },
      truckInfo: {
        uid: this.props.state.Authorization.authorization.entityUid
      },
      driverInfo: {}
    };

    var response = await actions.truckCheckInAction(data);
    if (response && Number(response.status) === 200) {
      this.props.dispatch(actions.isLoading(false));

      callback();
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.checkInEntity);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  getTruckDetail = async callback => {
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.getTruckDetailAction(
      this.props.state.Authorization.authorization.entityUid
    );
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      if (response.data.data[0].truckInfo) {
        await this.props.dispatch(
          actions.truckDetailSuccess(response.data.data[0])
        );
        // await this.props.dispatch(
        //   actions.SaveCheckinStatus(response.data.data[0].truckInfo.status)
        // );
        await this.props.dispatch(actions.SaveCheckinStatus(1));
        let personalConfig = this.props.state.DriverData.personalConfig || {};
        let logData = {
          type: "on_check_in",
          dto: {
            data: {
              Authorization: this.props.state.Authorization.authorization,
              DriverData: this.props.state.DriverData,
              TruckData: this.props.state.Truck,
              AppConfig: this.props.state.AppConfig,
              LocationUpdateConfig: {
                onJobLocationUpdateInterval:
                  personalConfig.onJobLocationUpdateInterval,
                idleLocationUpdateInterval:
                  personalConfig.idleLocationUpdateInterval,
                jobOfferDuration: personalConfig.jobOfferDuration,
                notMovingLocationUpdateInterval:
                  personalConfig.notMovingLocationUpdateInterval,
                distanceForOnTheWayMoving:
                  this.props.state.DriverData.networkConfig
                    .distanceForOnTheWayNotMoving || 0.2
              }
            },
            error: false,
            errorMessage: ""
          }
        };
        notification.postMessage(logData);
        callback();
      } else {
        this.showAlertModal(false, {
          error: "An error has occurred."
        });
        sendErrorLog({
          data: {
            error:
              "An error has occurred on getTruckDetail(activateapp) detail."
          }
        });
      }
      //this.props.dispatch(actions.authDataSuccess(response.data));
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.getTruckDetail, callback);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  getTruckConfig = async callback => {
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.getTruckConfigAction(
      this.props.state.Authorization.authorization.entityUid
    );
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      if (response.data.data[0].appInfo) {
        this.props.dispatch(actions.truckConfigSuccess(response.data.data[0]));
        callback();
      } else {
        this.showAlertModal(false, {
          error: "An error has occurred."
        });
        sendErrorLog({
          data: {
            error:
              "An error has occurred on getTruckConfig(activateapp) detail."
          }
        });
      }
      //this.props.dispatch(actions.authDataSuccess(response.data));
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.getTruckConfig, callback);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  initTestData = () => {
    if (process.env.REACT_APP_COUNTRY === "AU") {
      this.initTestDataAU();
    } else if (process.env.REACT_APP_COUNTRY === "US") {
      this.initTestDataUS();
    }
  };

  initTestDataUS = () => {
    var env = process.env.REACT_APP_API_END_POINT;
    if (env == "dev02") {
      client.setHostURL("https://" + env + "-apis-ecs.urgent.ly/v4");
      client.setHostURLV3("https://" + env + "-apis-ecs.urgent.ly/v3");
    } else {
      client.setHostURL("https://" + env + "-apis.urgent.ly/v4");
      client.setHostURLV3("https://" + env + "-apis.urgent.ly/v3");
    }
    client.setConfigURL(
      "https://" +
        process.env.REACT_APP_CONFIG_END_POINT +
        "-configs.urgent.ly/configs"
    );
    client.setAuthURL(
      "https://" +
        env +
        "-auth.urgent.ly/provider-entities/activation-code/validate"
    );
    client.setRefreshURL(
      "https://" + env + "-auth.urgent.ly/provider-entities/auth/refresh"
    );
    client.setApp("driverApp");
    client.setDeviceOsVersion("23");
    client.setDevice("Android");
    client.setIndustry("Roadside");
    client.setAPIVersion("3.0.0");
    client.setAppVersion("1.0.0");
    client.setCountryCode("+1");
    client.setTruckAction("true");
    client.setCountryISOCode("US");
    let Location = {
      latitude: 23.022132,
      longitude: 72.569382,
      address: "Bodakdev, Ahmedabad, Gujarat 380054",
      street: "Bodakdev",
      city: "Ahmedabad",
      state: "Gujarat",
      zip: "380054"
      // address: "2601 Mart St, Brisbane City",
      // city: "Brisbane",
      // street: null,
      // latitude: -27.46794,
      // longitude: 153.02809,
      // zip: 4072,
      // state: "QLD"
    };
    this.props.dispatch(actions.updateLocationSuccess(Location));
  };

  initTestDataAU = () => {
    client.setHostURL("https://dev02-apis.allianzau.urgent.ly/v4");
    client.setHostURLV3("https://dev02-apis.allianzau.urgent.ly/v3");
    client.setConfigURL("https://dev02-configs.allianzau.urgent.ly/configs");
    client.setAuthURL(
      "https://dev02-auth.allianzau.urgent.ly/provider-entities/activation-code/validate"
    );
    client.setRefreshURL(
      "https://dev02-auth.allianzau.urgent.ly/provider-entities/auth/refresh"
    );
    client.setApp("driverApp");
    client.setDeviceOsVersion("23");
    client.setDevice("Android");
    client.setIndustry("Roadside");
    client.setAPIVersion("3.0.0");
    client.setAppVersion("1.0.0");
    client.setCountryCode("+1");
    client.setTruckAction("true");
    client.setCountryISOCode("AU");
    let Location = {
      latitude: 23.022132,
      longitude: 72.569382,
      address: "Bodakdev, Ahmedabad, Gujarat 380054",
      street: "Bodakdev",
      city: "Ahmedabad",
      state: "Gujarat",
      zip: "380054"
      // address: "2601 Mart St, Brisbane City",
      // city: "Brisbane",
      // street: null,
      // latitude: -27.46794,
      // longitude: 153.02809,
      // zip: 4072,
      // state: "QLD"
    };
    this.props.dispatch(actions.updateLocationSuccess(Location));
  };
  getHashValue(key) {
    var matches = window.location.hash.match(new RegExp(key + "=([^&]*)"));
    return matches ? matches[1] : null;
  }

  handleAppInit = async data => {
    if (data.browser) {
      //client.setAuthToken(response.data.accessToken);
      let activationCode = "81069613";
      if (window.location.hash.indexOf("activation-code") !== -1) {
        activationCode = this.getHashValue("activation-code");
      }
      this.initTestData();
      this.startApp(activationCode);
      this.setState({
        loadView: true
      });
    } else {
      //alert(JSON.stringify(data.dto.appData));
      client.setConfigURL(data.dto.initData.config);
      client.setAuthURL(
        data.dto.initData.auth + "provider-entities/activation-code/validate"
      );
      client.setRefreshURL(
        data.dto.initData.auth + "provider-entities/auth/refresh"
      );
      client.setApp("driverApp");
      client.setDeviceOsVersion(data.dto.appData.os);
      client.setDevice(data.dto.appData.device);
      if (["dev01", "dev02", "dev03"].indexOf(data.dto.initData.env) != -1) {
        client.setEnvirontmentString("dev");
      } else {
        client.setEnvirontmentString(data.dto.initData.env);
      }
      client.setAppVersion(data.dto.appData.appVersion);
      client.setIndustry("Roadside");
      client.setAPIVersion("3.0.0");
      client.setCountryCode(data.dto.initData.countryCode);
      client.setTruckAction(data.dto.initData.truckAction);
      client.setCountryISOCode(data.dto.initData.countryISO);
      // alert(JSON.stringify(data.dto.appData))
      try {
        let address;
        let addressblock = {
          latitude: data.dto.appData.location.latitude,
          longitude: data.dto.appData.location.longitude
        };
        try {
          address = await reverseGeocode(data.dto.appData.location);
          addressblock = {
            latitude: data.dto.appData.location.latitude,
            longitude: data.dto.appData.location.longitude,
            address: address.address.customAddress,
            city: address.address.city,
            state: address.address.state,
            street: address.address.street,
            zip: address.address.postalCode
          };
        } catch (er) {
          let logData = {
            data: {
              error: JSON.stringify({
                request: data.dto.appData.location,
                response: er.message,
                message: "Reverse Geocode Fail"
              })
            }
          };

          sendErrorLog(logData, er);
        }
        this.props.dispatch(actions.updateLocationSuccess(addressblock));
      } catch (er) {
        let logData = {
          data: {
            error: JSON.stringify({
              request: data.dto.appData.location,
              response: er.message,
              message: "Reverse Geocode Fail"
            })
          }
        };

        sendErrorLog(logData, er);
      }
      this.props.dispatch(actions.saveInitAppData(data.dto.appData));
      this.props.dispatch(
        actions.saveVolumeLevel(data.dto.appData.volumeLevel)
      );

      //store dispatch unread count
      this.props.dispatch(
        actions.saveDispatchChatNotificationCount(
          parseInt(data.dto.appData.dispatchUnreadChatCount)
        )
      );

      if (data.type === "app_state") {
        if (data.dto.authorization && data.dto.authorization.accessToken) {
          client.setAuthToken(data.dto.authorization.accessToken);
        }
        if (
          data.dto.AppConfig &&
          data.dto.AppConfig.configs &&
          data.dto.AppConfig.configs.config
        ) {
          client.setHostURL(data.dto.AppConfig.configs.config["api.url.v4"]);
        }
        try {
          await this.restoreDataOnAppKilled(data);
        } catch (er) {}

        if (
          process.env.REACT_APP_COUNTRY === "AU" &&
          data.dto.authorization &&
          data.dto.authorization.expirationTime &&
          new Date().getTime() > data.dto.authorization.expirationTime
        ) {
          let userData = {
            type: postType.LOGOUT,
            dto: {
              data: {},
              error: false,
              errorMessage: ""
            }
          };
          notification.postMessage(userData);
          this.props.history.push({
            pathname: "/activate-app",
            state: {
              code: data.dto.authorization.code
            }
          });
        } else {
          let page = data.dto.appState.page;
          if (page === "pre-trip") {
            this.props.history.push({
              pathname: "/" + page,
              state: {
                history: "select-truck"
              }
            });
          } else {
            if (data.dto && data.dto.code) {
              this.props.history.push({
                pathname: "/" + page,
                state: {
                  code: data.dto.code
                }
              });
            } else {
              this.props.history.push("/" + page);
            }
          }
        }
      } else if (data.type === "init_kill_app_data") {
        //alert(JSON.stringify(data.dto.DriverData));
        client.setAuthToken(data.dto.authorization.accessToken);
        if (
          data.dto.AppConfig &&
          data.dto.AppConfig.configs &&
          data.dto.AppConfig.configs.config
        ) {
          client.setHostURL(data.dto.AppConfig.configs.config["api.url.v4"]);
        }
        try {
          await this.restoreDataOnAppKilled(data);
        } catch (er) {}
        if (
          process.env.REACT_APP_COUNTRY === "AU" &&
          data.dto.authorization.expirationTime &&
          new Date().getTime() > data.dto.authorization.expirationTime
        ) {
          let userData = {
            type: postType.LOGOUT,
            dto: {
              data: {},
              error: false,
              errorMessage: ""
            }
          };
          notification.postMessage(userData);
          this.props.history.push({
            pathname: "/activate-app",
            state: {
              code: data.dto.authorization.code
            }
          });
        } else if (data.dto.pushDto) {
          let pushDto = data.dto.pushDto;
          let jobNumber = pushDto.jobNumber;
          if (Number(pushDto.messageKey) === 3001) {
            this.props.history.push({
              pathname: "/job-offer",
              state: {
                jobData: {
                  service: {
                    number: jobNumber
                  }
                }
              }
            });
          } else if (
            Number(pushDto.messageKey) === 3012 ||
            Number(pushDto.messageKey) === 3013
          ) {
            let data = {
              jobId: jobNumber,
              history: "job-list"
            };
            Events.trigger(EventList.job.job_detail, data);
          } else if (Number(pushDto.messageKey) === 3006) {
            this.props.history.push("/job-list");
          } else if (Number(pushDto.messageKey) === 3050) {
            this.props.history.push({
              pathname: "/dispatch-chat",
              state: {
                jobData: {
                  service: {
                    number: jobNumber
                  }
                }
              }
            });
          } else {
            this.props.history.push("/home");
          }
        } else {
          this.props.history.push("/home");
        }
      } else {
        //alert('else')
        let activationCode = "035716";
        this.startApp(data.dto.activationCode);
      }

      this.setState({
        loadView: true
      });
    }
  };

  restoreDataOnAppKilled = async data => {
    if (data.dto && data.dto.authorization) {
      await this.props.dispatch(
        actions.restoreAuthDataSuccess(data.dto.authorization)
      );
    }
    await this.props.dispatch(
      actions.driverFlowToggle({ appFlow: "KILLED_APP_FLOW" })
    );
    if (data.dto && data.dto.DriverData && data.dto.DriverData.truckDetail) {
      await this.props.dispatch(
        actions.truckDetailSuccess(data.dto.DriverData.truckDetail)
      );
    }

    if (data.dto && data.dto.DriverData && data.dto.DriverData.driverConfig) {
      let driverConfig = {
        appInfo: data.dto.DriverData.driverConfig,
        config: {
          personnelConfigDTO: data.dto.DriverData.personalConfig,
          networkConfig: data.dto.DriverData.networkConfig
        }
      };
      await this.props.dispatch(actions.driverConfigSuccess(driverConfig));
    }

    // await this.props.dispatch(
    //   actions.SaveCheckinStatus(data.dto.DriverData.checkInStatus)
    // );

    if (data.dto && data.dto.DriverData && data.dto.DriverData.driverDetail) {
      await this.props.dispatch(
        actions.DriverDetailSuccess(data.dto.DriverData.driverDetail)
      );
    }

    if (data.dto && data.dto.DriverData && data.dto.DriverData.truckConfig) {
      let truckConfig = {
        appInfo: data.dto.DriverData.truckConfig,
        config: {
          personnelConfigDTO: data.dto.DriverData.personalConfig,
          networkConfig: data.dto.DriverData.networkConfig
        }
      };
      await this.props.dispatch(actions.truckConfigSuccess(truckConfig));
    }

    if (data.dto && data.dto.AppConfig) {
      await this.props.dispatch(actions.restoreConfig(data.dto.AppConfig));
    }

    if (data.dto && data.dto.TruckData && data.dto.TruckData.selectedTruck) {
      await this.props.dispatch(
        actions.selectTruck(data.dto.TruckData.selectedTruck)
      );
    }

    if (
      data.dto &&
      data.dto.EquipmentData &&
      data.dto.EquipmentData.selectedEquipment
    ) {
      await this.props.dispatch(
        actions.selectEquipment(data.dto.EquipmentData.selectedEquipment)
      );
    }
    if (data.dto && data.dto.Indemnity) {
      await this.props.dispatch(
        indemnityActions.indemnityReferralSuccess(data.dto.Indemnity)
      );
    }
    await this.props.dispatch(actions.tokenSent(false));
  };

  componentDidMount() {
    if (window.location.hash.indexOf("browser=1") !== -1) {
      if (
        this.props.history.location.state &&
        this.props.history.location.state.dontAgree
      ) {
        this.setState(
          {
            loadView: true
          },
          () => {
            this.startApp();
          }
        );
      } else {
        this.handleAppInit({ browser: true });
      }
    } else {
      if (
        this.props.history.location.state &&
        this.props.history.location.state.dontAgree
      ) {
        this.setState(
          {
            loadView: true
          },
          () => {
            this.startApp();
          }
        );
      } else {
        Events.on(
          EventList.native.init_app,
          "individual_flow",
          this.handleAppInit
        );
        window.addEventListener("load", () => {
          setTimeout(() => {
            this.startAppInformNative();
          }, 200);
        });
      }
    }
  }

  startAppInformNative = () => {
    let Data = {
      type: postType.START_APP,
      dto: {
        data: {
          startApp: true,
          startAppInformation: {
            googleMapKey: {
              android: process.env.REACT_APP_GOOGLE_KEY_ANDROID,
              ios: process.env.REACT_APP_GOOGLE_KEY_IOS
            }
          }
        },
        error: false,
        errorMessage: ""
      }
    };
    notification.postMessage(Data);
  };

  render() {
    let loaded = this.state.loadView;
    return (
      <div className="pg">
        <AlertMessage
          showAlert={this.state.showAlert}
          {...this.state.alertData}
        />
        {!loaded && <Loader loaded={loaded} />}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InitApp)
);
