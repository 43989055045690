export const ConnectionState = {
  Idle: "Idle",
  Connecting: "Connecting",
  Connected: "Connected",
  Failed: "Failed",
  MessageReceived: "Message"
};

export const DriverState = {
  None: "None",
  CheckedIN: "CheckedIN",
  CheckedOut: "CheckedOut",
  Busy: "Busy"
};

export const pubnubMessageKey = {
  CREATE_JOB: 3001,
  CUSTOMER_CANCEL: 3002,
  EXPIRE_JOB: 3003,
  GOA: 3005,
  DRIVER_CONFIRMED: 3006,
  PROVIDER_CANCEL: 3007,
  PROVIDER_CANCEL_FOR_CUSTOMER: 3008,
  JOB_COMPLETE: 3009,
  JOB_UPDATE: 3011,
  LOCAION_CHANGE: 3012,
  DROP_OFF_LOCATION_CHANGED: 3013,
  PROVIDER_ON_THE_WAY: 3021,
  PROVIDER_ARRIVED: 3022,
  JOB_NOT_ASSIGNED: 3040,
  JOB_OFFER: 3044,
  PROVER_REJECT: 3045,
  PROVIDER_WAKEUP: 3049,
  VEHICLE_ON_SHIFT: 3059,
  VEHICLE_OFF_SHIFT: 3060,
  PROVIDER_LEFT_JOB_SITE: 3067,
  ENTITY_STATUS_OFF_SHIFT: 3073,
  ENTITY_STATUS_ON_SHIFT: 3072
};
export const JobState = {
  // JOB_STATUS
  JOB_STATUS_NEW: 0,
  JOB_STATUS_FOUND_PROVIDERS: 1,
  JOB_STATUS_CONTACTING_PROVIDERS: 2,
  JOB_STATUS_ASSIGNED: 3,
  JOB_STATUS_PROVIDER_ON_WAY: 4,
  JOB_STATUS_PROVIDER_ARRIVED: 5,
  JOB_STATUS_CC_AUTHORIZED: 6,
  JOB_STATUS_CC_VOIDED: 7,
  JOB_STATUS_PROVIDER_REACHING_SITE: 10,
  JOB_STATUS_PROVIDER_LEFT_SITE: 11,
  JOB_STATUS_NO_CC_ONLINE: 88,
  JOB_STATUS_PAYMENT_FAILED: 89,
  JOB_STATUS_ALREADY_COMPLETE: 90,
  JOB_STATUS_SYSTEM_ENTERED: 101,
  JOB_STATUS_POTENTIAL_ONLY: 200,
  JOB_STATUS_TEST_ONLY: 300,
  /* JOB expiry related status code */
  JOB_STATUS_EXPIRED: 24,
  JOB_STATUS_NOT_FOUND_PROVIDERS: 20,
  JOB_STATUS_COMPLETED_BY_PROVIDER_GOA: 25,
  JOB_STATUS_CLOSED_GOA_NO_PAYMENT: 93,
  JOB_STATUS_NO_CC_ON_FILE: 99,
  /* JOB cancel related status code. */
  JOB_STATUS_CANCELLED_BY_CUSTOMER: 21,
  JOB_STATUS_CANCELED_BY_PROVIDER: 9,
  JOB_STATUS_CANCELED_BY_PROVIDER_FOR_CUSTOMER: 22,
  JOB_STATUS_PROVIDER_CANCEL_BY_CONSUMER_REQUEST: 97,
  /* Receipt related status code */
  JOB_STATUS_COMPLETE_BY_PROVIDER: 8,
  JOB_STATUS_CLOSED_AS_EXPIRED: 91,
  JOB_STATUS_CLOSED_ALL_PROVIDER_REJECTED: 96,
  JOB_STATUS_CLOSED: 100,
  JOB_STATUS_CLOSED_PAID_BY_CASH: 92,
  JOB_STATUS_CLOSED_NO_PAYMENT_NEEDED: 98,
  JOB_STATUS_CLOSED_GOA: 99
};

export const ProviderState = {
  // provider status codes */
  PROVIDER_CONTACTING: 0,
  PROVIDER_CONFIRMED: 1104,
  DRIVER_CONFIRMED: 1105,
  DRIVER_ON_THE_WAY: 1101,
  DRIVER_ARRIVED: 1102,
  DRIVER_ARRIVING: 1103,
  DRIVER_LEFT_ONSITE: 1210
};
export const Listener = {
  PubnubJobMessageReceived: "PubnubJobMessageReceived",
  JobOffer: "JobOffer",
  DriverConfirmed: "DriverConfirmed",
  JobCancelledByCustomer: "JobCancelledCustomer",
  JobExpired: "JobExpired",
  JobCreated: "JobCreated",
  JobAssigned: "JobAssigned",
  FoundProvider: "FoundProvider",
  GoneOnArrival: "GoneOnArrival",
  ProviderCancel: "ProviderCancel",
  ProviderCancelForCusomer: "ProviderCancelForCusomer",
  JobNotAssigned: "JobNotAssigned",
  JobComplete: "JobComplete",
  JobUpdate: "JobUpdate",
  LocationChanged: "LocationChanged",
  DroppOffLocationChanged: "DroppOffLocationChanged",
  ProviderOnTheWay: "ProviderOnTheWay",
  ProviderArrived: "ProviderArrived",
  ProviderReject: "ProviderReject",
  ProviderWakeUp: "ProviderWakeUp",
  ProviderLeftJobSite: "ProviderLeftJobSite",
  EntityOnShift: "EntityOnShift",
  EntityOffShift: "EntityOffShift",
  VehicleOnShift: "VehicleOnShift",
  VehicleOffShift: "VehicleOffShift"
};

export const messageCount = 15;
