import PubNub from "pubnub";
import config from "./config";
import { Listener, pubnubMessageKey } from "./constant";
import { Events } from "pr-utility/event-manager/event";
import notification from "pr-utility/utils/notification";

let connection;

const identifier = () =>
  Math.random()
    .toString(10)
    .slice(12);

export const connect = () => {
  if (connection) {
    return connection;
  }

  connection = new Promise((resolve, reject) => {
    const uuid = identifier();
    const options = Object.assign({}, config.client, { uuid });
    const pubnub = new PubNub(options);

    const initialHandler = {
      status: statusEvent => {
        switch (statusEvent.category) {
          case "PNConnectedCategory":
          case "PNNetworkUpCategory":
            resolve(pubnub);
            break;
          case "PNDisconnectedCategory":
          case "PNNetworkDownCategory":
            reject(new Error("Received a network-down message"));
            break;
          default:
            return;
        }

        pubnub.removeListener(initialHandler);

        pubnub.addListener({
          message: function() {
            if (process.env.NODE_ENV === "development") {
              console.log("Pubnub: message received");
              console.log(arguments);
            }
            let pubnubData = {
              type: "pubnub_info",
              isjobdto: false,
              dto: {
                data: arguments,
                error: false
              }
            };
            notification.log(pubnubData);
            if (arguments.length > 0) {
              let message = arguments[0].message;
              let jobInfo = JSON.parse(message.message);
              proceedWithInfo(jobInfo, message.messageKey);
            }
          },
          status: statusEvent => {
            switch (statusEvent.category) {
              case "PNDisconnectedCategory":
              case "PNNetworkDownCategory":
                connect(); // reconnect
                break;
              default:
            }
          }
        });
      }
    };

    pubnub.addListener(initialHandler);

    return handshake(pubnub)
      .then(() => resolve({ uuid, pubnub }))
      .catch(reject);
  });

  return connection;
};

export const disconnect = () => connect().then(({ pubnub }) => pubnub.stop());

const handshake = pubnub =>
  new Promise((resolve, reject) => {
    pubnub.time(status => {
      if (status.error) {
        reject(
          new Error(
            `PubNub service failed to respond to time request: ${status.error}`
          )
        );
      } else {
        resolve(pubnub);
      }
    });
  });

export const subscribe = (channel, presenceHandler, messageHandler) => {
  connect().then(({ pubnub }) => {
    pubnub.subscribe({
      channels: [channel],
      withPresence: false
    });
  });

  return {
    unsubscribe: () => {
      return connect().then(handle => handle.unsubscribe({ channel }));
    }
  };
};

export const proceedWithInfo = (jobInfo, key) => {
  switch (Number(key)) {
    case pubnubMessageKey.CREATE_JOB:
      Events.trigger(Listener.JobOffer, jobInfo);
      break;
    case pubnubMessageKey.DRIVER_CONFIRMED:
      Events.trigger(Listener.DriverConfirmed, jobInfo);
      break;
    case pubnubMessageKey.CUSTOMER_CANCEL:
      Events.trigger(Listener.JobCancelledByCustomer, jobInfo);
      break;
    case pubnubMessageKey.EXPIRE_JOB:
      Events.trigger(Listener.JobExpired, jobInfo);
      break;
    case pubnubMessageKey.GOA:
      Events.trigger(Listener.GoneOnArrival, jobInfo);
      break;
    case pubnubMessageKey.PROVIDER_CANCEL:
      Events.trigger(Listener.ProviderCancel, jobInfo);
      break;
    case pubnubMessageKey.PROVIDER_CANCEL_FOR_CUSTOMER:
      Events.trigger(Listener.ProviderCancelForCusomer, jobInfo);
      break;
    case pubnubMessageKey.JOB_NOT_ASSIGNED:
      Events.trigger(Listener.JobNotAssigned, jobInfo);
      break;
    case pubnubMessageKey.JOB_COMPLETE:
      Events.trigger(Listener.JobComplete, jobInfo);
      break;
    case pubnubMessageKey.JOB_UPDATE:
      Events.trigger(Listener.JobUpdate, jobInfo);
      break;
    case pubnubMessageKey.LOCAION_CHANGE:
      Events.trigger(Listener.LocationChanged, jobInfo);
      break;
    case pubnubMessageKey.DROP_OFF_LOCATION_CHANGED:
      Events.trigger(Listener.DroppOffLocationChanged, jobInfo);
      break;
    case pubnubMessageKey.PROVIDER_ON_THE_WAY:
      Events.trigger(Listener.ProviderOnTheWay, jobInfo);
      break;
    case pubnubMessageKey.PROVIDER_ARRIVED:
      Events.trigger(Listener.ProviderArrived, jobInfo);
      break;
    // case pubnubMessageKey.JOB_OFFER:
    //   Events.trigger(Listener.JobOffer, jobInfo); // TO-DO have to change as 3001 doing same as 3044
    //   break;
    case pubnubMessageKey.PROVER_REJECT:
      Events.trigger(Listener.ProviderReject, jobInfo);
      break;
    case pubnubMessageKey.PROVIDER_WAKEUP:
      Events.trigger(Listener.ProviderWakeUp, jobInfo);
      break;
    case pubnubMessageKey.VEHICLE_ON_SHIFT:
      Events.trigger(Listener.VehicleOnShift, jobInfo);
      break;
    case pubnubMessageKey.PROVIDER_LEFT_JOB_SITE:
      Events.trigger(Listener.ProviderLeftJobSite, jobInfo);
      break;
    case pubnubMessageKey.ENTITY_STATUS_ON_SHIFT:
      Events.trigger(Listener.EntityOnShift, jobInfo);
      break;
    case pubnubMessageKey.ENTITY_STATUS_OFF_SHIFT:
      Events.trigger(Listener.EntityOffShift, jobInfo);
      break;
    case pubnubMessageKey.VEHICLE_OFF_SHIFT:
      Events.trigger(Listener.VehicleOffShift, jobInfo);
      break;
    default:
    // console.log("Pubnub: message received: No Match found");
  }
};
