import React from "react";

const AlertMessage = props => {
  let {
    showAlert = false,
    header = "",
    body = "",
    leftText = "",
    rightText = "",
    leftOnClick = () => {},
    rightOnClick = () => {}
  } = props;
  return (
    /*eslint-disable no-script-url*/
    <div className={`pg dialog ${showAlert ? "show" : "hide"}`}>
      <div className="dialogBox pb0">
        <h2 className="title">{header}</h2>
        <p className="errorBody">{body}</p>
        <div className="row">
          <div className="control">
            <div className="d-flex">
              {leftText && (
                <div className="col pl-0 pr5 bRight">
                  <a
                    href="javascript:void(0)"
                    className="lnk"
                    onClick={leftOnClick}
                  >
                    {leftText}
                  </a>
                </div>
              )}
              {rightText && (
                <div className="col pr-0 pl5">
                  <a
                    href="javascript:void(0)"
                    className="lnk strong"
                    onClick={rightOnClick}
                  >
                    {rightText}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="overlay" />
    </div>
  );
};

export default AlertMessage;
