import React from "react";

class Header extends React.Component {
  /*eslint-disable no-script-url*/
  render() {
    let backButton,
      rightButton,
      headerClassName = "";

    if (this.props.left) {
      if (this.props.backButtonText) {
        backButton = (
          <a className="lnk" title="" onClick={this.props.backButton}>
            {this.props.backButtonText}
          </a>
        );
      } else {
        backButton = (
          <a className="lnk" title="" onClick={this.props.backButton}>
            <i className="icon ion-chevron-left" />
          </a>
        );
      }
    }
    if (this.props.right) {
      if (this.props.rightButtonText) {
        rightButton = (
          <a className="lnk" title="" onClick={this.props.rightButton}>
            {this.props.rightButtonText}
          </a>
        );
      } else {
        rightButton = (
          <a className="lnk" title="" onClick={this.props.rightButton}>
            <i className="icon ion-ios-arrow-right" />
          </a>
        );
      }
    }

    if (
      (!this.props.left && !this.props.right) ||
      (!this.props.left && this.props.right && !this.props.rightButtonText) ||
      (!this.props.right && this.props.left && !this.props.backButtonText) ||
      (this.props.left &&
        this.props.right &&
        !this.props.backButtonText &&
        !this.props.rightButtonText)
    ) {
      headerClassName = "withIconOnly";
    }

    return (
      <header className={headerClassName}>
        <div className="left">{backButton}</div>
        <div className="title">{this.props.title}</div>
        <div className="right">{rightButton}</div>
      </header>
    );
  }
}

export default Header;
