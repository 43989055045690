import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { TICK_IMG } from "pr-utility/images/index";
import * as actions from "./Actions";
import InfiniteScroll from "react-infinite-scroll-component";
import AlertMessage from "../CommonComponent/AlertMessage";
import { FormattedMessage } from "react-intl";
import Header from "../CommonComponent/Header";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
import notification from "pr-utility/utils/notification";
import { postType } from "pr-utility/utils/postMessage";
import { LogAllEvent, EventId } from "pr-utility/app-events/app-event-logger";
class SelectTruck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTruckId: null,
      truckUid: null,
      truckList: [],
      showAlert: false,
      alertData: {},
      noDataBlock: false,
      limit: Math.ceil((window.innerHeight - 103) / 63 + 3),
      offset: 0,
      hasMoreData: true,
      from: this.props.location.state
        ? this.props.location.state.from || ""
        : ""
    };

    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    if (this.state.from) {
      this.props.history.push("/home");
    } else {
      this.props.history.push("/user");
    }
  }
  saveTruck = () => {
    this.vehicleDriverAssignmnet(() => {
      //this.props.dispatch(actions.activationCodeEntered(true));
      this.chooseNextAction();
      // this.props.history.push("/home");
      //this.props.history.push("/pre-trip");
    }, this.state.truckUid);
  }
  selectTruck = truck => {
    
    if (
      truck.driverInfo &&
      truck.driverInfo.uid !==
        this.props.state.Authorization.authorization.entityUid
    ) {
      let alertData = {
        header: "Busy",
        body: `${
          truck.truckInfo.name
        } is currently busy. Do you want to select this vehicle anyway?`,
        rightText: "Select",
        leftText: "Cancel",
        leftOnClick: () => {
          this.setState({ showAlert: false });
        },
        rightOnClick: () => {
          this.setState({ selectedTruckId: truck.truckInfo.id,truckUid:truck.truckInfo.uid });
          this.props.dispatch(actions.selectTruck(truck.truckInfo));
          if(process.env.REACT_APP_COUNTRY === 'US'){
            this.setState({ showAlert: false });
          } else {
            this.vehicleDriverAssignmnet(() => {
              //this.props.history.push("/pre-trip");
              this.chooseNextAction();
            }, truck.truckInfo.uid);
          }
        }
      };
      this.setState({ showAlert: true, alertData: alertData });
    } else {
      this.setState({ selectedTruckId: truck.truckInfo.id,truckUid:truck.truckInfo.uid });
      this.props.dispatch(actions.selectTruck(truck.truckInfo));
      if(process.env.REACT_APP_COUNTRY === 'US'){
        this.setState({ showAlert: false });
      } else {
        this.vehicleDriverAssignmnet(() => {
          this.props.dispatch(actions.activationCodeEntered(true));
          this.chooseNextAction();
          // this.props.history.push("/home");
          //this.props.history.push("/pre-trip");
        }, truck.truckInfo.uid);
      }
    }
  };

  vehicleDriverAssignmnet = async (callback, truckId) => {
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.vehicleDriverAssignmentAction(
      truckId,
      this.props.state.Authorization.authorization.entityUid
    );
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      if (response.data.data.length) {
        callback();
      } else {
        this.showAlertModal(false, {
          error: "An error has occurred."
        });
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.vehicleDriverAssignmnet, [
        callback,
        truckId
      ]);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  chooseNextAction = () => {
    if (process.env.REACT_APP_COUNTRY === 'AU') {
      this.props.history.push({
        pathname: "/select-equipment",
        state: {
          history: "select-truck",
          from: this.state.from
        }
      });
    } else if (process.env.REACT_APP_COUNTRY === 'US') {
        this.TechnicianCheckIn(() => {
          let personalConfig = this.props.state.DriverData.personalConfig || {};
          let logData = {
            type: "on_check_in",
            dto: {
              data: {
                Authorization: this.props.state.Authorization.authorization,
                DriverData: this.props.state.DriverData,
                TruckData: this.props.state.Truck,
                AppConfig: this.props.state.AppConfig,
                LocationUpdateConfig: {
                  onJobLocationUpdateInterval:
                  personalConfig.onJobLocationUpdateInterval,
                  idleLocationUpdateInterval:
                  personalConfig.idleLocationUpdateInterval,
                  jobOfferDuration: personalConfig.jobOfferDuration,
                  notMovingLocationUpdateInterval:
                  personalConfig.notMovingLocationUpdateInterval,
                  distanceForOnTheWayMoving:
                  this.props.state.DriverData.networkConfig
                      .distanceForOnTheWayNotMoving || 0.2
                }
              },
              error: false,
              errorMessage: ""
            }
          };
          notification.postMessage(logData);
          this.props.dispatch(actions.preTripAnswerFromUser(true));
          this.props.history.push("/home");
      });
    }
  }


  TechnicianCheckIn = async callback => {
    LogAllEvent(
      "",
      EventId.DRIVER_CHECKIN,
      this.props.state.Authorization.authorization.entityUid,
      this.props.state.Authorization.authorization.entityType
    );
    let data = {
      location: {
        latitude: this.props.state.Location.latitude || -27.46794,
        longitude: this.props.state.Location.longitude || 153.02809,
        street: this.props.state.Location.street,
        city: this.props.state.Location.city,
        state: this.props.state.Location.state,
        zipCode: this.props.state.Location.zip
      },
      truckInfo: {},
      driverInfo: {
        uid: this.props.state.Authorization.authorization.entityUid,
        checkInVehicle: true
      }
    };
    // /*eslint-disable no-unused-expressions*/
    // if(this.props.state.Truck && this.props.state.Truck.selectedTruck && this.props.state.Truck.selectedTruck.status === 0) {
    //   data.driverInfo.checkInVehicle = true;
    // }

    this.props.dispatch(actions.isLoading(true));
    const response = await actions.TechnicianCheckIn(data);
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      if (response.data.data[0].driverInfo) {
        let driverData = this.props.state.DriverData.driverDetail;
        driverData.driverInfo.status = 1;
        driverData.driverInfo.reachable = "AVAILABLE";
        await this.props.dispatch(actions.DriverDetailSuccess(driverData));
        callback();
      } else {
        this.showAlertModal(false, {
          error: "An error has occurred."
        });
        sendErrorLog({
          data: {
            error: "An error has occurred on TechnicianCheckIn(pretrip) detail."
          }
        });
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.TechnicianCheckIn, callback);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  skipTruckSelect = () => {
    let alertData = {
      header: <FormattedMessage id="home.heading.skip_truck" />,
      body: <FormattedMessage id="home.skip_truck_text" />,
      rightText: <FormattedMessage id="profile.pre-trip.no" />,
      leftText: <FormattedMessage id="profile.pre-trip.yes" />,
      leftOnClick: () => {
        // this.props.history.push("/home");
        this.props.history.push("/home");
      },
      rightOnClick: () => {
        this.setState({ showAlert: false });
      }
    };
    this.setState({ showAlert: true, alertData: alertData });
  };
  showAlertModal = (netWorkError, response, callback, params) => {
    let alertData;
    if (netWorkError) {
      if (response && response.code && response.code === "ECONNABORTED") {
        alertData = {
          header: <FormattedMessage id="common.timeout_header" />,
          body: <FormattedMessage id="common.timeout_message" />,
          rightText: <FormattedMessage id="common.cancel" />,
          leftOnClick: () => {
            this.setState({ showAlert: false });
            if (params && Array.isArray(params) && params.length > 1) {
              callback(...params);
            } else if (params) {
              callback(params);
            } else {
              callback();
            }
          },
          leftText: <FormattedMessage id="common.retry" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      } else {
        alertData = {
          header: "",
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.close" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      }
    } else {
      if (response) {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: response.data ? (
            response.data.error ? (
              response.data.error
            ) : (
              <FormattedMessage id="profile.error_msg" />
            )
          ) : (
            <FormattedMessage id="profile.error_msg" />
          ),
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      } else {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      }
    }
    this.setState({ showAlert: true, alertData: alertData });
  };
  componentDidMount() {
    LogAllEvent(
      "",
      EventId.ON_SELECT_VEHICLE_SCREEN,
      this.props.state.Authorization.authorization.entityUid,
      this.props.state.Authorization.authorization.entityType
    );
    this.loadVehicles();
  }

  loadVehicles = async () => {
    await this.getVehicleList();

    if (!this.state.from) {
      //Do not change case of any key here which we are posting to native
      let page = "select-truck";
      let data = {
        AppConfig: this.props.state.AppConfig,
        authorization: this.props.state.Authorization.authorization,
        DriverData: this.props.state.DriverData
      };
      let type = postType.APP_STATE;
      notification.sendMessageToNative(page, data, type);
    }
  };

  fetchMoreData = async () => {
    this.setState({ offset: this.state.offset + this.state.limit });
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.getVehicleListAction(
      this.props.state.Authorization.authorization.entityUid,
      this.state.limit,
      this.state.offset
    );
    if (response && Number(response.status) === 200) {
      if (response.data.data.length > 0) {
        this.setState({
          truckList: this.state.truckList.concat(response.data.data)
        },()=>{
          let truckAppendedData = this.state.truckList;
          /*truckAppendedData.sort(function(a, b) {
           var first = a.truckInfo.name.toUpperCase();
           var second = b.truckInfo.name.toUpperCase();
           return first < second ? -1 : first > second ? 1 : 0;
           });*/
          this.setState({
            truckList: truckAppendedData,
            hasMoreData: true
          });
        });
      } else {
        this.setState({ offset: 0, hasMoreData: false });
      }
      this.props.dispatch(actions.isLoading(false));
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.fetchMoreData);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  getVehicleList = async () => {
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.getVehicleListAction(
      this.props.state.Authorization.authorization.entityUid,
      this.state.limit,
      this.state.offset
    );
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      let truckData = response.data.data;
      /*truckData.sort(function(a, b) {
        var first = a.truckInfo.name.toUpperCase();
        var second = b.truckInfo.name.toUpperCase();
        return first < second ? -1 : first > second ? 1 : 0;
      });*/
      this.setState({ truckList: truckData });
      //this.props.dispatch(actions.authDataSuccess(response.data));
      response.data.data.length > 0
        ? this.setState({ noDataBlock: false })
        : this.setState({ noDataBlock: true });
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.getVehicleList);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };
  renderTruckList = truckList => {
    if (!this.state.noDataBlock) {
      return truckList.map((truck, idx) => {
        return (
          <li key={truck.truckInfo.id}>
            {process.env.REACT_APP_COUNTRY === 'US' && truck.truckInfo && truck.truckInfo.externalId &&
            truck.truckInfo.truckType && (
              <span className="text dark" onClick={() => this.selectTruck(truck)}>
                {`${truck.truckInfo.externalId} (${truck.truckInfo.truckType})`}
              </span>
            )}
            {process.env.REACT_APP_COUNTRY !== "US" && (
              <span className="text dark" onClick={() => this.selectTruck(truck)}>
                {truck.truckInfo.name || truck.truckInfo.id}
              </span>
            )}
            {this.state.selectedTruckId === truck.truckInfo.id ? (
              <span className="tick">
                <img src={TICK_IMG} title="" alt="" />
              </span>
            ) : (
              ""
            )}
          </li>
        );
      });
    } else {
      return (
        <div className="jobList">
          <div className="dataRow ">
            <div className="head">
              <FormattedMessage id="jobs_list.not_found" />
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    /*let loaded = this.props.state.AppConfig.is_loading;*/
      let page_header,wrapper_class;
    if(process.env.REACT_APP_COUNTRY === 'US'){
      page_header = <FormattedMessage id="profile.select_vehicle" />;
      wrapper_class = 'parent_us';
    } else {
      page_header = <FormattedMessage id="profile.select_truck" />;
    }
    let truckList = this.state.truckList;
    /*eslint-disable no-script-url*/
    return (
      <div className={`pg bgGray ${wrapper_class}`}>
        <Header
          title={page_header}
          left={true}
          backButton={this.goBack}
          // right={true}
          // rightButtonText="Skip"
          // rightButton={this.skipTruckSelect}
        />

        {/* <Loader loaded={!loaded} /> */}
        <AlertMessage
          showAlert={this.state.showAlert}
          {...this.state.alertData}
        />
         { this.state.truckList.length && process.env.REACT_APP_COUNTRY === 'US' && 
        
        <div className="content pb20" >
          <h2 className="listViewTitle">
            <FormattedMessage id="profile.vehicles" />
          </h2>
          <div id="scrollableDiv" className="trucksWrap">
          <div className="listViewBox ">
            <InfiniteScroll
              dataLength={this.state.truckList.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMoreData}
              scrollableTarget="scrollableDiv"
              endMessage=""
            >
              <ul className="type3"> {this.renderTruckList(truckList)}</ul>
            </InfiniteScroll>
          </div>
          </div>
          {/* {this.state.truckList && (
            <div className="listViewBox">
              <ul className="type3">
                {this.state.truckList.map((truck, idx) => {
                  return (
                    <li key={truck.truckInfo.id}>
                      <span
                        className="text"
                        onClick={() => this.selectTruck(truck.truckInfo)}
                      >
                        {truck.truckInfo.id}
                      </span>
                      {this.state.selectedTruckId === truck.truckInfo.id ? (
                        <span className="tick">
                          <img src={TICK_IMG} title="" alt="" />
                        </span>
                      ) : (
                        ""
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )} */}
 
        </div>}
        { this.state.truckList.length && process.env.REACT_APP_COUNTRY === 'AU' && 
        
        <div className="content pb20" id="scrollableDiv">
          <h2 className="listViewTitle">
             <FormattedMessage id="profile.trucks" />
          </h2>
          <div className="trucksWrap">
          <div className="listViewBox ">
            <InfiniteScroll
              dataLength={this.state.truckList.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMoreData}
              scrollableTarget="scrollableDiv"
              endMessage={<p style={{ textAlign: "center" }}>
                  <b>
                    <FormattedMessage id="jobs_history.all" />{" "}
                  </b>
                </p>}
            >
              <ul className="type3"> {this.renderTruckList(truckList)}</ul>
            </InfiniteScroll>
          </div>
          </div>
        </div>}
        { this.state.truckList.length && process.env.REACT_APP_COUNTRY === 'US' && 
            <footer className="bottom_fix bgGray">
              <div className="container clearfix pl-0 pr-0 text-center">
                <a
                  href="javascript:void(0)"
                  className={"btn mb0 " + (this.state.selectedTruckId ? "" : "disabled")}
                  onClick={() => this.saveTruck()}
                >
                  <FormattedMessage id="profile.save" />
                </a>
              </div>
            </footer>
          }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectTruck)
);
