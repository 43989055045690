import React, { Component } from "react";

import { FormattedMessage } from "react-intl";
import { FLOW } from "../../flow";
import { getNextPage } from "pr-utility/utils/utils";
import { postType } from "pr-utility/utils/postMessage";
import notification from "pr-utility/utils/notification";
import { LOGO, CLOSE } from "pr-utility/images/index";
import { connect } from "react-redux";
import * as preTripActions from "../Pre-Trip/Actions";

class Disclaimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preTripAnswer: this.props.state.DriverData.answers
    };
  }

  componentDidMount() {
    var type = postType.APP_STATE;
    var page = "disclaimer";
    var data = {};
    if (
      this.props.state.Authorization &&
      this.props.state.Authorization.authorization
    ) {
      data = {
        AppConfig: this.props.state.AppConfig,
        authorization: this.props.state.Authorization.authorization,
        DriverData: this.props.state.DriverData,
        TruckData: this.props.state.Truck
      };
    } else {
      data = {};
    }
    notification.sendMessageToNative(page, data, type);
  }

  nextPage = () => {
    if (
      this.props.state.Authorization &&
      this.props.state.Authorization.authorization &&
      this.props.state.Authorization.authorization.accessToken
    ) {
      this.props.history.push("/pre-trip");
    } else {
      if (this.props.state.Home.codeEntered === true) {
        this.props.history.push("/pre-trip");
      } else {
        this.props.history.push(getNextPage(FLOW));
      }
    }
  };

  dontAgree = () => {
    this.props.dispatch(preTripActions.preTripAnswerStatus(true));
    this.props.history.push({
      pathname: "/",
      state: {
        dontAgree: "true"
      }
    });
    // let dontAgreeTerm = {
    //   type: postType.DONT_AGREE_TERM,
    //   dto: {
    //     data: {},
    //     error: false,
    //     errorMessage: ""
    //   }
    // }
    // notification.postMessage(dontAgreeTerm);
  };

  dismissError = () => {
    this.setState({
      preTripAnswer: true
    });
  };

  render() {
    const page_title = <FormattedMessage id="profile.disclaimer.title" />;
    const para1 = <FormattedMessage id="profile.disclaimer.para1" />;
    const para2 = <FormattedMessage id="profile.disclaimer.para2" />;
    const para3 = <FormattedMessage id="profile.disclaimer.para3" />;
    const agree = <FormattedMessage id="profile.disclaimer.agree" />;
    const disagree = <FormattedMessage id="profile.disclaimer.disagree" />;
    const errorTitle = <FormattedMessage id="profile.disclaimer.error" />;
    const errorText = <FormattedMessage id="profile.disclaimer.error_text" />;

    /*eslint-disable no-script-url*/
    return (
      <div className="pg disclaimer">
        {this.state.preTripAnswer === false ? (
          <div className="pg cancel">
            <a
              href="javascript:void(0)"
              className="btn_close"
              onClick={() => this.dismissError()}
              title=""
            >
              <img src={CLOSE} title="" alt="" />
            </a>
            <div className="content">
              <div className="title">{errorTitle}</div>
              <div className="text">{errorText}</div>
            </div>
            <div className="overlay v1" />
          </div>
        ) : (
          ""
        )}
        <div className="content pt15">
          <div className="container">
            <div className="gap30" />
            <div className="mid_logo small">
              <img src={LOGO} title="" alt="" />
            </div>
            <h3 className="title">{page_title}</h3>
            <p>{para1}</p>
            <p>{para2}</p>
            <p>{para3}</p>
          </div>
        </div>
        <div className="bottom_fix shadow text-center">
          <div className="container clearfix pl-0 pr-0 text-center">
            <div className="twoButtons">
              <a
                href="javascript:void(0)"
                className="btn btnClose half mr5 mb0 col"
                onClick={() => this.dontAgree()}
              >
                {disagree}
              </a>
              <a
                href="javascript:void(0)"
                className="btn half ml5 mb0 col"
                onClick={() => this.nextPage()}
              >
                {agree}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

// function mapDispatchToProps(dispatch) {
//   return {
//     dispatch,
//     actions: bindActionCreators(actions, dispatch)
//   };
// }

export default connect(mapStateToProps)(Disclaimer);
