import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  partList: null,
  totalCost: null
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_PART: {
      return {
        ...state,
        partList: action.partList
      };
    }
    case types.DELETE_PART: {
      return {
        ...state,
        partList: action.partList
      }
    }
    case types.TOTAL_COST: {
      return {
        ...state,
        totalCost: action.cost
      }
    }
    case types.RESET_ADDED_PARTS: {
      return {
        partList: null,
        totalCost: null,
      }
    }
    default:
      return state;
  }
}
