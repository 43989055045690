import { serviceType } from "./constant";
import notification from "./notification";
import { postType } from "./postMessage";

export const countryList = {
  AU: "61",
  UK: "44",
  US: "1",
  IND: "91"
};

export const countryMobileLength = {
  AU: 12,
  UK: 12,
  US: 14,
  IND: 12
};

export const mobileNumberFormat = {
  AU: {
    blocks: [4, 3, 3],
    delimiters: [" ", " "],
    numericOnly: true
  },
  UK: {
    blocks: [4, 3, 3],
    delimiters: [" ", " "],
    numericOnly: true
  },
  US: {
    blocks: [0, 3, 0, 3, 4],
    delimiters: ["(", ")", " ", "-"],
    numericOnly: true
  },
  IND: {
    blocks: [4, 3, 3],
    delimiters: [" ", " "],
    numericOnly: true
  }
};

export function soundPlayback(startStopBool) {
  let Data = {
    type: postType.SOUND_PLAYBACK,
    dto: {
      data: {
        soundPlayback: startStopBool ? "start" : "stop"
      },
      error: false,
      errorMessage: ""
    }
  };
  notification.postMessage(Data);
}

export function formatPhoneNumber(tel, countryCode) {
  var numbers,
    chars,
    phoneNumber = "",
    i;
  if (countryCode === 1 || !countryCode) {
    if (tel) {
      numbers = tel.replace(/\D/g, "");
      chars = { 0: "(", 3: ") ", 6: "-" };
      for (i = 0; i < numbers.length; i++) {
        phoneNumber += (chars[i] || "") + numbers[i];
      }
      return phoneNumber;
    }
  } else if (countryCode === 61) {
    if (tel) {
      numbers = tel.replace(/\D/g, "");
      chars = { 4: " ", 7: " " };
      for (i = 0; i < numbers.length; i++) {
        phoneNumber += (chars[i] || "") + numbers[i];
      }
      return phoneNumber;
    }
  } else if (countryCode === 44) {
    if (tel) {
      numbers = tel.replace(/\D/g, "");
      chars = { 4: " ", 7: " " };
      for (i = 0; i < numbers.length; i++) {
        phoneNumber += (chars[i] || "") + numbers[i];
      }
      return phoneNumber;
    }
  } else if (countryCode === 91) {
    if (tel) {
      numbers = tel.replace(/\D/g, "");
      chars = { 4: " ", 7: " " };
      for (i = 0; i < numbers.length; i++) {
        phoneNumber += (chars[i] || "") + numbers[i];
      }
      return phoneNumber;
    }
  } else {
    return tel;
  }
}

export function clearLocalStorage() {
  localStorage.clear();
}

export function isValidNumber(event) {
  var theEvent = event || window.event;
  var key;

  // Handle paste
  if (theEvent.type === "paste") {
    key = event.clipboardData.getData("text/plain");
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  var regex = /[0-9]|\./;
  if (!regex.test(key)) {
    return false;
  } else {
    return true;
  }
}

export function onlyNumberAllowed(event) {
  if (event.shiftKey || event.ctrlKey || event.altKey) {
    event.preventDefault();
    return false;
  } else {
    const charCode = event.which || event.keyCode;
    if (
      charCode !== 8 &&
      charCode !== 0 &&
      ((charCode <= 47 || charCode >= 58) &&
        (charCode <= 95 || charCode >= 106))
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}

export function upperCaseNumberRestriction(event) {
  if (event.shiftKey || event.ctrlKey || event.altKey) {
    event.preventDefault();
    return false;
  } else {
    var charCode = event.keyCode || event.which;
    if (
      charCode !== 8 &&
      charCode !== 0 &&
      (charCode <= 47 || charCode >= 58) &&
      (charCode <= 64 || charCode >= 91)
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}

export function getDistanceFromLatLon(lat1, lon1, lat2, lon2, unit = "m") {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  if (unit === "m") {
    d = d / 1.609344; // Distance in mile
  }
  d = d.toFixed(2);
  return d;
}

export function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function checkTokenExpiration(expirationTime) {
  if (new Date().getTime() > expirationTime - 600 * 1000) {
    return true;
  } else {
    return false;
  }
}

export function getNextPage(flow) {
  var currentPage = window.location.hash.replace("#/", "");
  var pages = [];
  for (var page in flow) {
    pages.push(flow[page]);
  }
  var index = pages.indexOf(currentPage);
  return pages[index + 1];
}

export function checkForStateChange(oldState, newState) {
  if (oldState.provider && newState.provider) {
    let statusSequesnceNonTow = [0, 1101, 1102],
      statusSequesnceTow = [0, 1101, 1102, 1210];
    if (oldState.provider.status === newState.provider.status) {
      return false;
    } else {
      let indexOfNewStatus, indexOfOldStatus;
      if (oldState.service.serviceType === serviceType.TOW) {
        indexOfNewStatus = statusSequesnceTow.indexOf(newState.provider.status);
        indexOfOldStatus = statusSequesnceTow.indexOf(oldState.provider.status);
      } else {
        indexOfNewStatus = statusSequesnceNonTow.indexOf(
          newState.provider.status
        );
        indexOfOldStatus = statusSequesnceNonTow.indexOf(
          oldState.provider.status
        );
      }
      if (indexOfNewStatus <= indexOfOldStatus) {
        return false;
      } else {
        return true;
      }
    }
  }
}

export function isActionEnabled(entity, truckAction) {
  if (entity === "DRIVER" || (entity === "VEHICLE" && truckAction === "true")) {
    return true;
  } else {
    return false;
  }
}

export function informNativeForJobStatusChange(
  status,
  personalConfig,
  distanceForOnTheWayNotMoving
) {
  let userData = {
    type: postType.DRIVER_NAVIGATION,
    dto: {
      data: {
        Status: `job_${status}`,
        LocationUpdateConfig: {
          onJobLocationUpdateInterval:
            personalConfig.onJobLocationUpdateInterval,
          idleLocationUpdateInterval: personalConfig.idleLocationUpdateInterval,
          jobOfferDuration: personalConfig.jobOfferDuration,
          notMovingLocationUpdateInterval:
            personalConfig.notMovingLocationUpdateInterval,
          distanceForOnTheWayMoving: distanceForOnTheWayNotMoving || 0.2
        }
      },
      error: false,
      errorMessage: ""
    }
  };
  notification.postMessage(userData);
}

export function deepMergeCustom(dataJson, baseJson) {
  for (var obj in dataJson) {
    if (dataJson[obj] instanceof Object) {
      if (baseJson[obj] === undefined) {
        baseJson[obj] = dataJson[obj];
      }
      deepMergeCustom(dataJson[obj], baseJson[obj]);
    } else {
      baseJson[obj] = dataJson[obj];
    }
  }
}

export function onBlurEvent() {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 10);
}

export function scroll(elementClass, duration=100, effect='slow') {
    const $ = window.$;
    setTimeout(function() {
      $(document).ready(function() {
        $(elementClass).animate(
          { scrollTop: $(document).height() + 100 },
          effect
        );
        return false;
      });
    }, duration);
  }

// export function verifyToken(successCallBack, errorCallBack) {
//   let params = {
//     accessToken: APIConfigs.AUTH_CODE,
//     refreshToken: APIConfigs.REFRESH_TOKEN
//   };
//   let rqURL = APIConfigs.AUTH_URL + LoginAPI.refreshToken.get;
//   return UrgentlyAPIs.POST(rqURL, params, false)
//     .then(response => {
//       if (response && response.accessToken) {
//         APIConfigs.AUTH_CODE = response.accessToken;
//         APIConfigs.REFRESH_TOKEN = response.refreshToken;
//         let tokenExpiration = new Date().getTime() + response.expiresIn * 1000;
//         successCallBack(response, tokenExpiration);
//       }
//     })
//     .catch(error => {
//       errorCallBack();
//     });
// }
