import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Events } from "pr-utility/event-manager/event";
import Loader from "../CommonComponent/Loader";
import { withRouter } from "react-router-dom";
import * as actions from "./Actions";
import { logo_urgently, LOGO } from "pr-utility/images/index";

import client from "pr-utility/config/client";
import { EventList } from "pr-utility/event-manager/event-list";
import { reverseGeocode } from "pr-utility/utils/mapUtils";
import CheckAuthorization from "pr-utility/common/CheckAuthorization";
import notification from "pr-utility/utils/notification";
import { postType } from "pr-utility/utils/postMessage";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";

class PoweredBy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadView: false
    };
  }
  startTimerForDisclaimer = () => {
    setTimeout(() => {
      this.props.history.push("/disclaimer");
    }, 2000);
  };

  handleAppInit = async data => {
    if (data.browser) {
      //client.setAuthToken(response.data.accessToken);
      client.setHostURL("https://dev02-apis.allianzau.urgent.ly/v4");
      client.setHostURLV3("https://dev02-apis.allianzau.urgent.ly/v3");
      client.setConfigURL("https://dev02-configs.allianzau.urgent.ly/configs");
      client.setAuthURL(
        " https://dev02-auth.allianzau.urgent.ly/provider-entities/activation-code/validate"
      );
      client.setRefreshURL(
        " https://dev02-auth.allianzau.urgent.ly/provider-entities/auth/refresh"
      );
      client.setApp("driverApp");
      client.setDeviceOsVersion("23");
      client.setDevice("Android");
      client.setIndustry("Roadside");
      client.setAPIVersion("3.0.0");
      client.setAppVersion("1.0.0");
      client.setCountryCode("+1");
      client.setTruckAction("true");
      client.setCountryISOCode("US");
      let Location = {
        latitude: 23.022132,
        longitude: 72.569382,
        address: "Bodakdev, Ahmedabad, Gujarat 380054",
        street: "Bodakdev",
        city: "Ahmedabad",
        state: "Gujarat",
        zip: "380054"
        // address: "2601 Mart St, Brisbane City",
        // city: "Brisbane",
        // street: null,
        // latitude: -27.46794,
        // longitude: 153.02809,
        // zip: 4072,
        // state: "QLD"
      };
      this.props.dispatch(actions.updateLocationSuccess(Location));
      this.startTimerForDisclaimer();
      this.setState({
        loadView: true
      });
    } else {
      // alert(JSON.stringify(data.dto.appData))
      client.setConfigURL(data.dto.initData.config);
      client.setAuthURL(
        data.dto.initData.auth + "provider-entities/activation-code/validate"
      );
      client.setRefreshURL(
        data.dto.initData.auth + "provider-entities/auth/refresh"
      );
      client.setApp("driverApp");
      client.setDeviceOsVersion(data.dto.appData.os);
      client.setDevice(data.dto.appData.device);
      if (["dev01", "dev02", "dev03"].indexOf(data.dto.initData.env) !== -1) {
        client.setEnvirontmentString("dev");
      } else {
        client.setEnvirontmentString(data.dto.initData.env);
      }
      client.setAppVersion(data.dto.appData.appVersion);
      client.setIndustry("Roadside");
      client.setAPIVersion("3.0.0");
      client.setCountryCode(data.dto.initData.countryCode);
      client.setTruckAction(data.dto.initData.truckAction);
      client.setCountryISOCode(data.dto.initData.countryISO);
      // alert(JSON.stringify(data.dto.appData))
      try {
        let address;
        let addressblock = {
          latitude: data.dto.appData.location.latitude,
          longitude: data.dto.appData.location.longitude
        };
        try {
          address = await reverseGeocode(data.dto.appData.location);
          addressblock = {
            latitude: data.dto.appData.location.latitude,
            longitude: data.dto.appData.location.longitude,
            address: address.address.customAddress,
            city: address.address.city,
            state: address.address.state,
            street: address.address.street,
            zip: address.address.postalCode
          };
        } catch (er) {
          let logData = {
            data: {
              error: JSON.stringify({
                request: data.dto.appData.location,
                response: er.message,
                message: "Reverse Geocode Fail"
              })
            }
          };

          sendErrorLog(logData, er);
        }
        this.props.dispatch(actions.updateLocationSuccess(addressblock));
      } catch (er) {
        let logData = {
          data: {
            error: JSON.stringify({
              request: data.dto.appData.location,
              response: er.message,
              message: "Reverse Geocode Fail"
            })
          }
        };

        sendErrorLog(logData, er);
      }

      this.props.dispatch(actions.saveInitAppData(data.dto.appData));
      this.props.dispatch(
        actions.saveVolumeLevel(data.dto.appData.volumeLevel)
      );

      //store dispatch unread count
      this.props.dispatch(
        actions.saveDispatchChatNotificationCount(
          parseInt(data.dto.appData.dispatchUnreadChatCount)
        )
      );

      if (data.type === "app_state") {
        if (data.dto.authorization && data.dto.authorization.accessToken) {
          client.setAuthToken(data.dto.authorization.accessToken);
        }
        if (
          data.dto.AppConfig &&
          data.dto.AppConfig.configs &&
          data.dto.AppConfig.configs.config
        ) {
          client.setHostURL(data.dto.AppConfig.configs.config["api.url.v4"]);
        }
        try {
          await this.restoreDataOnAppKilled(data);
        } catch (er) {}

        if (
          data.dto.authorization &&
          data.dto.authorization.expirationTime &&
          new Date().getTime() > data.dto.authorization.expirationTime
        ) {
          let userData = {
            type: postType.LOGOUT,
            dto: {
              data: {},
              error: false,
              errorMessage: ""
            }
          };
          notification.postMessage(userData);
          this.props.history.push({
            pathname: "/activate-app",
            state: {
              code: data.dto.authorization.code
            }
          });
        } else {
          let page = data.dto.appState.page;
          if (page === "pre-trip") {
            this.props.history.push({
              pathname: "/" + page,
              state: {
                history: "select-truck"
              }
            });
          } else {
            if (data.dto && data.dto.code) {
              this.props.history.push({
                pathname: "/" + page,
                state: {
                  code: data.dto.code
                }
              });
            } else {
              this.props.history.push("/" + page);
            }
          }
        }
      } else if (data.type === "init_kill_app_data") {
        //alert(JSON.stringify(data.dto.DriverData.networkConfig));
        client.setAuthToken(data.dto.authorization.accessToken);
        client.setHostURL(data.dto.AppConfig.configs.config["api.url.v4"]);
        try {
          await this.restoreDataOnAppKilled(data);
        } catch (er) {}
        if (
          data.dto.authorization.expirationTime &&
          new Date().getTime() > data.dto.authorization.expirationTime
        ) {
          let userData = {
            type: postType.LOGOUT,
            dto: {
              data: {},
              error: false,
              errorMessage: ""
            }
          };
          notification.postMessage(userData);
          this.props.history.push({
            pathname: "/activate-app",
            state: {
              code: data.dto.authorization.code
            }
          });
        } else if (data.dto.pushDto) {
          let pushDto = data.dto.pushDto;
          let jobNumber = pushDto.jobNumber;
          if (Number(pushDto.messageKey) === 3001) {
            this.props.history.push({
              pathname: "/job-offer",
              state: {
                jobData: {
                  service: {
                    number: jobNumber
                  }
                }
              }
            });
          } else if (
            Number(pushDto.messageKey) === 3012 ||
            Number(pushDto.messageKey) === 3013
          ) {
            let data = {
              jobId: jobNumber,
              history: "job-list"
            };
            Events.trigger(EventList.job.job_detail, data);
          } else if (Number(pushDto.messageKey) === 3006) {
            this.props.history.push("/job-list");
          } else {
            this.props.history.push("/home");
          }
        } else {
          this.props.history.push("/home");
        }
      } else {
        this.startTimerForDisclaimer();
      }

      this.setState({
        loadView: true
      });
    }
  };

  restoreDataOnAppKilled = async data => {
    if (data.dto.authorization) {
      await this.props.dispatch(
        actions.authDataSuccess(data.dto.authorization)
      );
    }
    await this.props.dispatch(
      actions.driverFlowToggle({ appFlow: "KILLED_APP_FLOW" })
    );
    if (data.dto.DriverData.truckDetail) {
      await this.props.dispatch(
        actions.truckDetailSuccess(data.dto.DriverData.truckDetail)
      );
    }

    if (data.dto.DriverData && data.dto.DriverData.driverConfig) {
      let driverConfig = {
        appInfo: data.dto.DriverData.driverConfig,
        config: {
          personnelConfigDTO: data.dto.DriverData.personalConfig,
          networkConfig: data.dto.DriverData.networkConfig
        }
      };
      await this.props.dispatch(actions.driverConfigSuccess(driverConfig));
    }

    // await this.props.dispatch(
    //   actions.SaveCheckinStatus(data.dto.DriverData.checkInStatus)
    // );

    if (data.dto.DriverData.driverDetail) {
      await this.props.dispatch(
        actions.DriverDetailSuccess(data.dto.DriverData.driverDetail)
      );
    }

    if (data.dto.DriverData && data.dto.DriverData.truckConfig) {
      let truckConfig = {
        appInfo: data.dto.DriverData.truckConfig,
        config: {
          personnelConfigDTO: data.dto.DriverData.personalConfig,
          networkConfig: data.dto.DriverData.networkConfig
        }
      };
      await this.props.dispatch(actions.truckConfigSuccess(truckConfig));
    }

    if (data.dto.AppConfig) {
      await this.props.dispatch(actions.restoreConfig(data.dto.AppConfig));
    }

    if (data.dto.TruckData && data.dto.TruckData.selectedTruck) {
      await this.props.dispatch(
        actions.selectTruck(data.dto.TruckData.selectedTruck)
      );
    }

    if (data.dto.EquipmentData && data.dto.EquipmentData.selectedEquipment) {
      await this.props.dispatch(
        actions.selectEquipment(data.dto.EquipmentData.selectedEquipment)
      );
    }

    await this.props.dispatch(actions.tokenSent(false));
  };

  componentDidMount() {
    if (window.location.hash.indexOf("browser=1") !== -1) {
      if (
        this.props.history.location.state &&
        this.props.history.location.state.dontAgree
      ) {
        this.setState(
          {
            loadView: true
          },
          () => {
            this.startTimerForDisclaimer();
          }
        );
      } else {
        this.handleAppInit({ browser: true });
      }
    } else {
      if (
        this.props.history.location.state &&
        this.props.history.location.state.dontAgree
      ) {
        this.setState(
          {
            loadView: true
          },
          () => {
            this.startTimerForDisclaimer();
          }
        );
      } else {
        Events.on(
          EventList.native.init_app,
          "individual_flow",
          this.handleAppInit
        );
        window.addEventListener("load", () => {
          setTimeout(() => {
            this.startAppInformNative();
          }, 200);
        });
      }
    }
  }

  startAppInformNative = () => {
    let Data = {
      type: postType.START_APP,
      dto: {
        data: {
          startApp: true,
          startAppInformation: {
            googleMapKey: {
              android: process.env.REACT_APP_GOOGLE_KEY_ANDROID,
              ios: process.env.REACT_APP_GOOGLE_KEY_IOS
            }
          }
        },
        error: false,
        errorMessage: ""
      }
    };
    notification.postMessage(Data);
  };

  render() {
    let loaded = this.state.loadView;
    return (
      <div>
        {!loaded && <Loader loaded={loaded} />}
        {loaded && (
          <div className="pg splashpoweredby">
            {/* <CheckAuthorization /> */}
            <div className="content">
              <div className="container">
                <div className="mid_logo pt90">
                  <img src={LOGO} title="" alt="" />
                </div>
              </div>
            </div>
            <div className="bottom_fix text-center pb40">
              <div className="about_logo">
                <img src={logo_urgently} title="" alt="" />
              </div>
            </div>
          </div>
        )}
        <div className="opacZero pabsolute">
          <div className="sfFont">Test SF Fonts</div>
          <div className="sfFontL">Test SF Fonts</div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PoweredBy)
);
