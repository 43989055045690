/**
 * Created by pratik.panchal on 24-09-2018.
 */
import history from "pr-utility/history/history";
import { Events } from "pr-utility/event-manager/event";
import { EventList } from "pr-utility/event-manager/event-list";
import client from "../config/client";
import { LogAllEvent, EventId } from "pr-utility/app-events/app-event-logger";
import { soundPlayback } from "pr-utility/utils/utils";

/**
 * Login Module
 * @param data
 */
var openLogin = data => {
  history.push("/");
};
var openActivateApp = data => {
  history.push("/activate-app");
};

/**
 * Home module
 * @param data
 */
var loginSuccess = data => {
  //contract check
  /*if
    (
        data.initData.providerInfo.id &&
            data.initData.driverInfo.id &&
            data.initData.driverInfo.platform &&
            data.initData.driverInfo.providerId &&
            data.initData.location.lat &&
            data.initdata.location.lng &&
            data.authorization.accesstoken &&
            data.authorization.refreshToken &&
            data.pubnub.subscribeKey

    ){
        history.push("/home");
    }else{
        // error module to show missing data error
    }*/
  history.push("/home");
};

/**
 * Setting Module
 * @param data
 */
var openSetting = data => {
  //contract check
  /*if
     (
     data.initData.providerInfo.id &&
     data.initData.driverInfo.id &&
     data.initData.driverInfo.platform &&
     data.initData.driverInfo.providerId &&
     data.initData.location.lat &&
     data.initdata.location.lng &&
     data.authorization.accesstoken &&
     data.authorization.refreshToken &&
     data.pubnub.subscribeKey

     ){
     history.push("/home");
     }else{
     // error module to show missing data error
     }*/

  history.push("/settings");
};
var openJobList = data => {
  history.push("/settings/jobList");
};
/**
 * Check in/Check out module
 * @param data
 */
var openCheckIn = data => {
  history.push("/check-in");
};
/**
 * Check in/Check out module
 * @param data
 */
var openCheckOut = data => {};
/**
 * Job Module
 * @param data
 */
var jobDetail = data => {
  history.push({
    pathname: `/job-details/${data.jobId}`,
    state: {
      history: data.history
    }
  });
};
/**
 * Job Module
 * @param data
 */
var jobOffer = data => {
  history.push({
    pathname: "/job-offer",
    state: {
      jobData: data
    }
  });
};
/**
 * Job Module
 * @param data
 */
var statusTracker = data => {
  history.push("/status-tracker");
};
/**
 * Inspection Module
 * @param data
 */
var inspection = data => {};

/**
 * Chat Module
 * @param data
 */
var dispatchChat = data => {};
var dispatchChatNotification = data => {};

/**
 * Chat Module
 * @param data
 */
var customerChat = data => {};
/**
 * Handle Push Data
 * @param data
 */
var handlePushData = data => {
  // alert(JSON.stringify(data));
  let jobNumber = JSON.parse(data.dto).jobNumber;
  if (Number(JSON.parse(data.dto).messageKey) === 3022) {
    Events.trigger(EventList.home.app_near_service_location);
  } else if (Number(JSON.parse(data.dto).messageKey) === 3001) {
    //New Job Offer
    //alert('erer')
    LogAllEvent(
      jobNumber,
      EventId.JOB_OFFER_RECEIVED_VIA_PUSH,
      client.ENTITY_UID,
      client.ENTITY_TYPE
    );

    history.push({
      pathname: "/job-offer",
      state: {
        jobData: {
          service: {
            number: jobNumber
          }
        }
      }
    });
  } else if (
    Number(JSON.parse(data.dto).messageKey) === 3012 ||
    Number(JSON.parse(data.dto).messageKey) === 3013
  ) {
    let data = {
      jobId: jobNumber,
      history: "job-list"
    };

    Events.trigger(EventList.job.job_detail, data);
  } else if (Number(JSON.parse(data.dto).messageKey) === 3006) {
    soundPlayback(false);
    history.push("/job-list");
  } else if (
    JSON.parse(data.dto).notificationData &&
    Number(JSON.parse(data.dto).notificationData.messageKey) === 3050
  ) {
    if (JSON.parse(data.dto).onPushNotificationTap === true) {
      // https://urgently.atlassian.net/browse/HT-3020: change
      if (history.location.pathname === "/dispatch-chat") {
        Events.trigger(EventList.chat.dispatch_chat_notification, data);
      } else {
        history.push("/dispatch-chat");
      }
    } else {
      // trigger event
      Events.trigger(EventList.chat.dispatch_chat_notification, data);
    }
  }
};

var nativeMessage = eventData => {
  if (eventData.data) {
    /* alert(eventData.data);*/
    var data = eventData.data;
    // alert(JSON.stringify(data));
    switch (data.type) {
      case "init_app_data":
      case "init_kill_app_data":
      case "app_state":
        Events.trigger(EventList.native.init_app, data);
        break;
      case "send_log_success":
        Events.trigger(EventList.native.send_log_success, data);
        break;
      case "send_log_failure":
        Events.trigger(EventList.native.send_log_failure, data);
        break;
      case "reset_preference_success":
        Events.trigger(EventList.native.reset_preference_success, data);
        break;
      case "reset_preference_failure":
        Events.trigger(EventList.native.reset_preference_failure, data);
        break;
      case "send_push_data":
        handlePushData(data);
        break;
      case "add_profile_photo":
        Events.trigger(EventList.native.add_photo, data);
        break;
      case "location_update":
        Events.trigger(EventList.native.locationUpdate, data);
        break;
      case "inspection_complete":
        Events.trigger(EventList.native.post_inspection_complete, data);
        break;
      case "app_in_back_ground":
        Events.trigger(EventList.native.app_in_back_ground, data);
        break;
      case "app_in_fore_ground":
        Events.trigger(EventList.native.app_in_fore_ground, data);
        break;
      default:
        break;
    }
  }
};
export {
  openLogin,
  openActivateApp,
  loginSuccess,
  openSetting,
  openCheckIn,
  openCheckOut,
  jobDetail,
  jobOffer,
  statusTracker,
  inspection,
  dispatchChat,
  customerChat,
  dispatchChatNotification,
  openJobList,
  nativeMessage
};
