/**
 * List of event that can be used during communication between module
 */
const eventList = {
  init: {
    login: "login",
    activate_app: "activateApp"
  },
  login: {
    success: "login_success",
    failure: "login_failure"
  },
  authentication: {
    get_token: "get_access_token"
  },
  home: {
    location_update: "location_update",
    check_in: "check_in",
    checkout: "check_out",
    settings: "settings",
    driver_on_the_way: "driver_on_the_way",
    driver_on_site: "driver_on_site",
    driver_left_job_site: "driver_left_job_site",
    location_update_pubnub: "location_update_pubnub",
    drop_off_location_update_pubnub: "drop_off_location_update_pubnub",
    start_inspection: "start_inspection",
    job_update_pubnub: "job_update_pubnub",
    app_near_service_location: "app_near_service_location"
  },
  job: {
    job_detail: "job_detail",
    job_offer: "job_offer",
    status_tracker: "status_tracker"
  },
  inspection: {
    start: "start"
  },
  settings: {
    jobList: "jobList"
  },
  chat: {
    dispatch_chat_notification: "dispatch_chat_notification",
    dispatcher_chat: "dispatcher_chat",
    customer_chat: "customer_chat"
  },
  native: {
    init_app: "init_app_data",
    locationUpdate: "location_update",
    network_update: "network_update",
    send_log_success: "send_log_success",
    send_log_failure: "send_log_failure",
    reset_preference_success: "reset_preference_success",
    reset_preference_failure: "reset_preference_failure",
    reset_volume_level: "reset_volume_level",
    send_push_data: "handle_push_data",
    add_photo: "add_profile_photo",
    post_inspection_complete: "post_inspection_complete",
    app_in_back_ground: "app_in_back_ground",
    app_in_fore_ground: "app_in_fore_ground"
  }
};
export const EventList = eventList;
