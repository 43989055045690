import { createStore, applyMiddleware } from "redux";

import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise";

import * as reducer from "../reducers";
var store;
export default function configureStore(initialState) {
  if (process.env.NODE_ENV === "development") {
    const logger = createLogger();
    const middleWare = applyMiddleware(promise, thunk, logger);
    store=createStore(reducer.default, initialState, middleWare);
    return store;
  } else {
    const middleWare = applyMiddleware(promise, thunk);
    store=createStore(reducer.default, initialState, middleWare);
    return store;
  }
}
export function getStore() {
    return store;
}