import ApiConfigs from "../../config/client";

export const jobListDriver = (technicianUid, limit, offset) => {
  if (limit && offset !== undefined) {
    return `${
      ApiConfigs.HOST_URL
    }/provider-driver/jobs/active-list?id=${technicianUid}&limit=${limit}&offset=${offset}`;
  } else {
    return `${
      ApiConfigs.HOST_URL
    }/provider-driver/jobs/active-list?id=${technicianUid}`;
  }
};

export const jobListTruck = (Uid, limit, offset) => {
  if (limit && offset !== undefined) {
    return `${
      ApiConfigs.HOST_URL
    }/provider-vehicles/jobs/active-list?id=${Uid}&limit=${limit}&offset=${offset}`;
  } else {
    return `${
      ApiConfigs.HOST_URL
    }/provider-vehicles/jobs/active-list?id=${Uid}`;
  }
};

export const jobHistoryTruck = (Uid, limit, offset) => {
  return `${
    ApiConfigs.HOST_URL
  }/provider-vehicles/jobs/history?id=${Uid}&limit=${limit}&offset=${offset}`;
};

export const jobHistoryDriver = (Uid, limit, offset) => {
  return `${
    ApiConfigs.HOST_URL
  }/provider-driver/jobs/history?id=${Uid}&limit=${limit}&offset=${offset}`;
};

export const driverPerformanceURL = (providerId, driverId) => {
  return `${
    ApiConfigs.HOST_URL
  }/personnel/urgently:${providerId}:${driverId}/jobs/history?limit=100&offset=0`;
};
export const jobDetailDriver = (technicianUid, jobNumber) => {
  return `${
    ApiConfigs.HOST_URL
  }/provider-driver/${technicianUid}/job/${jobNumber}`;
};
export const jobDetailTruck = (technicianUid, jobNumber) => {
  return `${
    ApiConfigs.HOST_URL
  }/provider-vehicles/${technicianUid}/job/${jobNumber}`;
};

export const getAdditionalServiceInfo = (jobNumber) => {
  return `${
    ApiConfigs.HOST_URL
  }/service-instructions/?jobNumber=${jobNumber}`;
};
