export const postType = {
  START_APP: "start_app",
  START_INSPECTION: "start_inspection",
  USER_SESSION: "user_session",
  ADD_PHOTO: "add_profile_photo",
  NAVIGATE_BACK: "navigate_back",
  RESET_VOLUME: "reset_volume_level",
  SAVE_VOLUME: "save_volume_level",
  LOGOUT: "user_logout",
  RESET_PREFERENCE: "reset_preference",
  SEND_LOG: "send_log",
  INIT_KILL_APP_DATA: "init_kill_app_data",
  DONT_AGREE_TERM: "dont_agree_term",
  ERROR_INFO: "error_info",
  DRIVER_NAVIGATION: "driver_navigation",
  REFRESH_TOKEN: "refresh_token",
  APP_STATE: "app_state",
  OPEN_EXTERNAL_URL: "open_external_url",
  SOUND_PLAYBACK: "sound_playback",
  LOAD_WEB_VIEW: "load_web_view",
  SAVE_DISPATCH_UNREAD_CHAT_COUNT: "save_dispatch_unread_chat_count",
  VEHICLE_LOCATION:'vehicle_location'
};
