import LOADER_IMG_ICON from "./Spinner.svg";
import URGENTLY_LOGO_ICON from "./logo_favicon_urgently.svg";
import BMW_LOGO_ICON from "./logo_favicon_bmw.svg";
import STAR_ICON from "./ic_starInRound.svg";
import USER_ICON from "./ic_user.svg";
import LOCATION_ICON from "./ic_location_pin.svg";
import ARROW_ICON from "./ic_forward_arrow.svg";
import CAR_ICON from "./ic_car.svg";
import NOTES_ICON from "./ic_clipboard.svg";
import ETA_ICON from "./ic_timer.svg";
import logo_urgently_icon from "./PoweredBy_Color.svg";
import URGENTLY_NO_POWERED_BY from "./Urgently.svg";
import LOADING_ICON from "./Loading.svg";
import CLOSE_ICON from "./ic_close.svg";
import LOGO_ICON from "./AWP_Large.svg";
import logo_urgently_dark_icon from "./logo_urgently_dark.svg";
import TICK_IMG_ICON from "./ic_blue_tick.svg";
import USER01_IMAGE from "./user01.jpg";
import CALENDAR_ICON from "./ic_calendar.svg";
import SAD_FACE_ICON from "./ic_sad_face.svg";
import AVATAR_ICON from "./ic_avatar.svg";

export const LOADER_IMG = LOADER_IMG_ICON;
export const URGENTLY_LOGO = URGENTLY_LOGO_ICON;
export const BMW_LOGO = BMW_LOGO_ICON;
export const STAR = STAR_ICON;
export const USER = USER_ICON;
export const LOCATION = LOCATION_ICON;
export const ARROW = ARROW_ICON;
export const CAR = CAR_ICON;
export const NOTES = NOTES_ICON;
export const ETA = ETA_ICON;
export const logo_urgently = logo_urgently_icon;
export const URGENTLY_LOGO_NO_POWERED_BY = URGENTLY_NO_POWERED_BY;
export const LOADING = LOADING_ICON;
export const CLOSE = CLOSE_ICON;
export const LOGO = LOGO_ICON;
export const logo_urgently_dark = logo_urgently_dark_icon;
export const TICK_IMG = TICK_IMG_ICON;
export const USER01 = USER01_IMAGE;
export const CALENDAR = CALENDAR_ICON;
export const SAD_FACE = SAD_FACE_ICON;
export const AVATAR = AVATAR_ICON;
