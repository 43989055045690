import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  phoneNumber: null,
  truckCheckin: null,
  truckDetail: null,
  driverDetail: null,
  driverConfig: null,
  truckConfig: null,
  personalConfig: null,
  checkInStatus: 0,
  networkConfig: null,
  appFlow: "NORMAL",
  answers: null,
  otpSent: null
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.DRIVER_PHONE_NUMBER: {
      return {
        ...state,
        phoneNumber: action.response
      };
    }
    case types.ON_TRUCK_CHECKIN_SUCCESS: {
      return {
        ...state,
        truckCheckin: action.response
      };
    }
    case types.VEHICLE_DETAIL_SUCCESS: {
      return {
        ...state,
        truckDetail: action.response,
        checkInStatus: action.response.truckInfo.status
      };
    }
    case types.DRIVER_DETAIL_SUCCESS: {
      return {
        ...state,
        driverDetail: action.response,
        checkInStatus: action.response.driverInfo.status
      };
    }

    case types.ON_DRIVER_CONFIG_SUCCESS: {
      return {
        ...state,
        driverConfig: action.response.appInfo,
        personalConfig: action.response.config.personnelConfigDTO,
        networkConfig: action.response.config.networkConfig
      };
    }

    case types.ON_TRUCK_CONFIG_SUCCESS: {
      return {
        ...state,
        truckConfig: action.response.appInfo,
        personalConfig: action.response.config.personnelConfigDTO,
        networkConfig: action.response.config.networkConfig
      };
    }
    case types.SAVE_CHECKIN_STATUS: {
      return {
        ...state,
        checkInStatus: action.response
      };
    }
    case types.DRIVER_FLOW_CHANGE: {
      return {
        ...state,
        appFlow: action.response.appFlow
      };
    }
    case types.PRE_TRIP_ANS_STATUS: {
      return {
        ...state,
        answers: action.response
      };
    }
    case types.RESET_DRIVER_DATA: {
      return {
        ...initialState
      };
    }
    case types.OTP_SENT: {
      return {
        otpSent: action.boolean
      };
    }

    default:
      return state;
  }
}
