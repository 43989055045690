import { getStore } from "../../store";
import { postType } from "pr-utility/utils/postMessage";
import notification from "pr-utility/utils/notification";
// import { Events } from "pr-utility/event-manager/event";
// import { EventList } from "pr-utility/event-manager/event-list";
// import { disconnect } from "pr-utility/pubnub/pubnub";
import client from "pr-utility/config/client";
import * as actions from "./Actions";
// import { callbackify } from "util";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
//import { loadState } from "../../localStorage";
//const persistedState = loadState();
//const store = getStore();
//var shouldRefreshToken = false;
export const authConfig = {
  refreshTokenProgress: null
};
export async function checkAuthorization() {

      let expirationCheckTime = 10; /// in minutes
      let storeStates = getStore().getState();
      if (
          storeStates.Authorization &&
          storeStates.Authorization.authorization &&
          storeStates.Authorization.authorization.accessToken
      ) {
        let currentTime = new Date().getTime();
        let actualExpirationTime =
            storeStates.Authorization.authorization.expirationTime;
        let securityTokenExpirationTime = actualExpirationTime - expirationCheckTime * 60 * 1000;
        // first condition will check for expirationCheckTime
        // second condition will check if token already expire
        if ((securityTokenExpirationTime <= currentTime && currentTime <= actualExpirationTime) || (currentTime >= actualExpirationTime)) {
          await refreshToken();
          authConfig.refreshTokenProgress=null;
          return false;
        } else {
          return true;
        }

      }

  // //console.log("event info",event)
  // let storeStates=getStore().getState();
  // //console.log("store",getStore());
  // //console.log("store state",storeStates);
  // if (
  //     storeStates.Authorization &&
  //     storeStates.Authorization.authorization &&
  //     storeStates.Authorization.authorization.accessToken
  // ) {
  //   let actualExpirationTime = storeStates.Authorization.authorization.expirationTime;
  //   let securityTokenExpirationTime = storeStates.Authorization.authorization.expirationTime - 90 * 60 * 1000;
  //   let currentTime = new Date().getTime();
  //   if (currentTime > actualExpirationTime) {
  //       //console.log("inside auth token expired")
  //       let userData = {
  //         type: postType.LOGOUT,
  //         dto: {
  //           data: {},
  //           error: false,
  //           errorMessage: ""
  //         }
  //       };
  //       notification.postMessage(userData);
  //       disconnect();
  //       if(process.env.REACT_APP_COUNTRY === 'AU'){
  //         if (window.location.hash.indexOf("activate-app") === -1) {
  //           Events.trigger(EventList.init.activate_app, {code: storeStates.Authorization.authorization.code});
  //         }
  //       }
  //       return false;
  //   } else if (
  //       currentTime > securityTokenExpirationTime &&
  //       currentTime < actualExpirationTime &&
  //       shouldRefreshToken === false
  //   ) {
  //       //console.log("inside securityTokenExpirationTime")
  //     shouldRefreshToken = true;
  //     if(shouldRefreshToken && client.AUTH_CODE != storeStates.Authorization.authorization.accessToken) {
  //       initRefreshToken();
  //       //console.log("after init refresh")
  //     }
  //   }
  // }
  // return true;
}
// async function initRefreshToken(){
//     await refreshToken();
//     shouldRefreshToken = false;
// }

export async function refreshToken() {
  if (!authConfig.refreshTokenProgress) {
    authConfig.refreshTokenProgress =new Promise(async (resolve, reject) => {
      let store = getStore();
      const response = await actions.refreshToken(
          store.getState().Authorization.authorization
      );
      if (response) {
        if (response.accessToken) {
          client.setAuthToken(response.accessToken);
          let expirationTime = new Date().getTime() + response.expiresIn * 1000;
          response.expirationTime = expirationTime;
          store.dispatch(await actions.refreshTokenSuccess(response));
          // store.dispatch(actions.setExpirationTime(response));
          let data = {
            request: store.getState(),
            response: response
          };
          let logData = {
            data: {
              error: JSON.stringify({
                data: data,
                message: "refreshtoken=====refresh token received"
              })
            }
          };
          sendErrorLog(logData);
          let authData = {
            type: postType.REFRESH_TOKEN,
            dto: {
              data: {
                Authorization: {
                  ...response,
                  code: store.getState().Authorization.authorization.code
                }
              },
              error: false,
              errorMessage: ""
            }
          };
          notification.postMessage(authData);
          resolve();
        } else {
          let data = {
            request: store.getState().Authorization.authorization,
            response: response
          };
          let logData = {
            data: {
              error: JSON.stringify({
                data: data,
                message:
                    "refreshtoken=====called refresh token and not received access token"
              })
            }
          };
          sendErrorLog(logData);
          let userData = {
            type: postType.LOGOUT,
            dto: {
              data: {},
              error: false,
              errorMessage: ""
            }
          };
          notification.postMessage(userData);
          resolve();
        }
      } else {
        let data = {
          request: store.getState().Authorization.authorization,
          response: response
        };
        let logData = {
          data: {
            error: JSON.stringify({
              data: data,
              message:
                  "refreshtoken=====called refresh token and not received refresh token respose"
            })
          }
        };
        sendErrorLog(logData);
        let userData = {
          type: postType.LOGOUT,
          dto: {
            data: {},
            error: false,
            errorMessage: ""
          }
        };
        notification.postMessage(userData);
        resolve();
      }

    });


    }
  return authConfig.refreshTokenProgress;
}