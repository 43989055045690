import React from "react";
import ReactDOM from "react-dom";
import history from "pr-utility/history/history";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import { loadState } from "./localStorage";
import App from "./app";
import * as Sentry from "@sentry/browser";
import SentryErrorBoundary from "./Sentry";

//import { saveState } from "./localStorage";
//import throttle from "lodash/throttle";
import * as eventManager from "pr-utility/event-manager/event-manager";

import { lang } from "pr-utility/translations/index";
/*import login_en from "./pr-login/src/translations/en.json";
import home_en from "./pr-home/src/translations/en.json";
import settings_en from "./pr-settings/src/translations/en.json";*/

import client from "pr-utility/config/client";

import APIConfigs from "pr-utility/config/conf";

// import * as loginShell from "pr-login/src/shell";
// import * as homeShell from "pr-home/src/shell";
// import * as settingsShell from "pr-settings/src/shell";
//import * as jobShell from "pr-jobmanagement/src/shell";

//import registerServiceWorker from "./registerServiceWorker";

import "jquery/dist/jquery.min";
import "bootstrap/dist/js/bootstrap.min";
import "popper.js/dist/umd/popper.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "pr-utility/sass/ionicons.css";
import { loadCSS } from "pr-utility/sass/index";

import configureStore from "./store";
const persistedState = loadState();
const store = configureStore(persistedState);
// store.subscribe(
// 	throttle(() => {
// 		saveState(store.getState());
// 	}, 1000)
// );

// if (client.HOST_URL === "" && persistedState && persistedState.AppConfig.configs && persistedState.AppConfig.configs.config && persistedState.AppConfig.configs.config["api.url.v4"]) {
// 	client.HOST_URL = persistedState.AppConfig.configs.config["api.url.v4"];
// 	client.HOST_URL_V3 = persistedState.AppConfig.configs.config["api.url"];
// }

// if (client.AUTH_TOKEN === "" && persistedState && persistedState.Authorization.authorization && persistedState.Authorization.authorization.accessToken) {
// 	client.AUTH_TOKEN = persistedState.Authorization.authorization.accessToken;
// }

APIConfigs.configureClient(client);

loadCSS();

let messages_en = Object.assign({}, lang);

const messages = {
  en: messages_en
};

global.Intl = require("intl");

if (process.env.NODE_ENV == "production") {
  Sentry.init({
    dsn: APIConfigs.SENTRY_DSN,
    environment: window.location.hostname.includes(...client.DEV_END_POINTS)
      ? "dev-web"
      : "prod-web"
  });
}

ReactDOM.render(
  <SentryErrorBoundary>
    <IntlProvider
      locale="en"
      messages={messages["en"]}
      textComponent={React.Fragment}
    >
      <Router history={history}>
        <Provider store={store}>
          <App />
          {/*		  <Switch>

			<Route exact path="/" component={loginShell.PoweredBy} />
			<Route exact path="/disclaimer" component={loginShell.Disclaimer} />
			<Route exact path="/activate-app" component={loginShell.ActivateApp} />
			<Route exact path="/user" component={loginShell.User} />
			<Route exact path="/not-me" component={loginShell.NotMe} />
			<Route exact path="/select-truck" component={loginShell.SelectTruck} />
			<Route exact path="/pre-trip" component={loginShell.PreTrip} />
			<Route exact path="/profile" component={loginShell.DriverVerification} />

			<Route exact path="/home" component={homeShell.Home} />
			<Route exact path="/job-offer" component={homeShell.JobOffer}></Route>
			<Route exact path="/declined-job" component={homeShell.DeclinedJob}></Route>
			<Route exact path="/cancel-job/:id" component={homeShell.JobCancel}></Route>
			<Route exact path="/status-tracker" component={homeShell.StatusTracker} />
			<Route exact path="/entitlements" component={homeShell.Entitlements} />
			<Route exact path="/notes" component={homeShell.Notes} />
			<Route exact path="/services_completed" component={homeShell.ServicesCompleted} />
			<Route exact path="/add_part" component={homeShell.AddPart} />
			<Route exact path="/finish-job" component={homeShell.RemainingJobs} />

			<Route exact path="/job-list" component={settingsShell.JobsList} />
			<Route exact path="/settings" component={settingsShell.Settings} />
			<Route exact path="/about" component={settingsShell.About} />
			<Route exact path="/alert-volume" component={settingsShell.AlertVolume} />
			<Route exact path="/job-details/:id" component={settingsShell.JobDetails} />
			<Route exact path="/services" component={settingsShell.Services} />
			<Route exact path="/job-history" component={settingsShell.JobsList} />
			<Route exact path="/performance" component={settingsShell.Performance} />
			<Route exact path="/additional_details" component={settingsShell.AdditionalDetails} />
			<Route exact path="/actual_problem" component={settingsShell.ActualProblem} />
			<Route exact path="/other_info" component={settingsShell.OtherInfo} />

			 <Route exact path="/cancel-job/:id" component={jobShell.JobCancel}></Route>
				<Route exact path="/declined-job" component={jobShell.DeclinedJob}></Route>
				<Route exact path="/job-offer" component={jobShell.JobOffer}></Route>
				<Route exact path="/status-tracker" component={jobShell.StatusTracker} />
				<Route exact path="/home" component={jobShell.Home} />
				<Route exact path="/job-details/:id" component={jobShell.JobDetails} />
				<Route exact path="/job-list" component={jobShell.JobsList} />
				<Route exact path="/job-history" component={jobShell.JobsList} />
				<Route exact path="/customer_approval" component={jobShell.CustomerApproval} />
				<Route exact path="/vehicle_report" component={jobShell.VehicleReport} />
				<Route exact path="/referral_docket" component={jobShell.ReferralDocket} />

				<Route exact path="/" component={loginShell.ProviderVerification} />

		  </Switch> */}
        </Provider>
      </Router>
    </IntlProvider>
  </SentryErrorBoundary>,
  document.getElementById("root")
);

//registerServiceWorker();
