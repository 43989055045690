import * as types from "./actionTypes";
import * as network from "pr-utility/utils/network";
import client from "pr-utility/config/client";

export async function refreshToken(auth) {
  try {
    let params = {
      accessToken: auth.accessToken,
      refreshToken: auth.refreshToken,
      scope: auth.scope,
      entityType: auth.entityType,
      entityUid: auth.entityUid
    };
    let rqURL = client.getRefreshURL();
    const response = await network.POST_NO_TOKEN(rqURL, params);
    return response.data;
  } catch (err) {}
}

export function refreshTokenSuccess(response) {
  return dispatch => {
    dispatch({
      type: types.REFRESH_TOKEN,
      response
    });
  };
}

export function setExpirationTime(response) {
  return dispatch => {
    dispatch({
      type: types.SET_EXPIRATION_TIME,
      response
    });
  };
}

export function isLoading(bool) {
  return dispatch => {
    dispatch({
      type: types.IS_LOADING,
      bool
    });
  };
}

export function tokenSent(response) {
  return dispatch => {
    dispatch({
      type: types.TOKEN_SENT,
      response
    });
  };
}
