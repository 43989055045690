import * as types from "../../actionTypes";
import * as network from "pr-utility/utils/network";
import { providerManagement } from "pr-utility/utils/APISignature/ProviderVerificationAPI";
import {
  technicianCheckOut,
  technicianCheckIn,
  truckCheckIn,
  truckCheckOut,
  technicianDeviceToken,
  technicianLocationUpdate,
  vehicleDeviceToken,
  vehicleLocationUpdate,
  updateDriverReachable,
  getEntityDetail,
  getVehicleDetail,
  getIndemnity,
  postIndemnity,
  postSign,
  skipUserSign
} from "pr-utility/utils/APISignature/CheckInAPI";
import {
  jobListDriver,
  jobListTruck
} from "pr-utility/utils/APISignature/SettingsAPI";
import client from "pr-utility/config/client";

export async function getIndemnityData(jobID, fromType) {
  try {
    const response = await network.GET(getIndemnity(jobID, fromType));
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export function successindemnity(response) {
  return dispatch => {
    dispatch({
      type: types.INDEMNITY_SUCCESS,
      response
    });
  };
}

export async function providerVerify(phoneNumber) {
  try {
    const response = await network.GET(providerManagement(phoneNumber));
    return response.data;
  } catch (err) {}
}

export async function sendDeviceToken(entityUid, type, data) {
  try {
    if (type === "DRIVER") {
      const response = await network.PUT(
        technicianDeviceToken(entityUid),
        data
      );
      return response;
    } else {
      const response = await network.POST(vehicleDeviceToken(), data);
      return response;
    }
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export function DriverDetailSuccess(response) {
  return dispatch => {
    dispatch({
      type: types.DRIVER_DETAIL_SUCCESS,
      response
    });
  };
}

export async function sendLocationUpdate(entityUid, type, data) {
  try {
    const response = await network.PUT(
      type === "DRIVER"
        ? technicianLocationUpdate(entityUid)
        : vehicleLocationUpdate(entityUid),
      data
    );
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export function providerVerificationSuccess(response, phoneNumber) {
  return dispatch => {
    dispatch({
      type: types.PROVIDER_VERIFICATION_SUCCESS,
      response
    });
    dispatch({
      type: types.PROVIDER_PHONE_NUMBER,
      phoneNumber
    });
  };
}

export async function loadConfigKeys(params) {
  try {
    let configURL = client.getConfigURL() + "/keys";
    const response = await network.GET(configURL + params);
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export async function loadConfig(params) {
  try {
    let configURL = client.getConfigURL();
    const response = await network.GET(configURL + params);
    return response.data;
  } catch (err) {}
}

export async function refreshToken(auth) {
  try {
    let params = {
      accessToken: auth.accessToken,
      refreshToken: auth.refreshToken,
      scope: auth.scope,
      entityType: auth.entityType,
      entityUid: auth.entityUid
    };
    let rqURL = client.getRefreshURL();
    const response = await network.POST(rqURL, params);
    return response.data;
  } catch (err) {}
}

export function refreshTokenSuccess(response) {
  return dispatch => {
    dispatch({
      type: types.REFRESH_TOKEN,
      response
    });
  };
}

export function setExpirationTime(response) {
  return dispatch => {
    dispatch({
      type: types.SET_EXPIRATION_TIME,
      response
    });
  };
}

export function fetchSuccessConfig(response) {
  return dispatch => {
    dispatch({
      type: types.ON_GET_CONFIG_SUCESS,
      response
    });
  };
}
export function fetchSuccessConfigKeys(response) {
  return dispatch => {
    dispatch({
      type: types.ON_GET_CONFIG_KEYS_SUCCESS,
      response
    });
  };
}

export function isLoading(bool) {
  return dispatch => {
    dispatch({
      type: types.IS_LOADING,
      bool
    });
  };
}

export async function checkInDriver(data) {
  try {
    const response = await network.POST(technicianCheckIn(), data);
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export async function checkInTruck(data) {
  try {
    const response = await network.POST(truckCheckIn(), data);
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export async function checkOutDriver(data) {
  try {
    const response = await network.POST(technicianCheckOut(), data);
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export async function checkOutTruck(data) {
  try {
    const response = await network.POST(truckCheckOut(), data);
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export async function jobList(entityUid, entity) {
  try {
    const response = await network.GET(
      entity === "DRIVER" ? jobListDriver(entityUid) : jobListTruck(entityUid)
    );
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export async function sendDriverRechable(entityUid, data) {
  try {
    const response = await network.PUT(updateDriverReachable(entityUid), data);
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export function updateLocationSuccess(response) {
  return dispatch => {
    dispatch({
      type: types.UPDATE_LOCATION_SUCCESS,
      response
    });
  };
}
export function activeJobList(response) {
  return dispatch => {
    dispatch({
      type: types.ON_ADD_ACTIVE_JOB_LIST,
      response
    });
  };
}

export function clearActiveJobList() {
  return dispatch => {
    dispatch({
      type: types.ON_CLEAR_ACTIVE_JOB
    });
  };
}

export function onMessageReceived(response) {
  return dispatch => {
    dispatch({
      type: types.ON_MESSAGE_RECEIVED,
      response
    });
  };
}

export function SaveCheckinStatus(response) {
  return dispatch => {
    dispatch({
      type: types.SAVE_CHECKIN_STATUS,
      response
    });
  };
}

export function truckDetailSuccess(response) {
  return dispatch => {
    dispatch({
      type: types.VEHICLE_DETAIL_SUCCESS,
      response
    });
  };
}
export function activationCodeEntered(response) {
  return dispatch => {
    dispatch({
      type: types.ACTIVATION_CODE_ENTERED,
      response
    });
  };
}

export async function getEntityDetailAction(uid, entityType) {
  try {
    const response = await network.GET(
      entityType === "DRIVER" ? getEntityDetail(uid) : getVehicleDetail(uid)
    );
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export function tokenSent(response) {
  return dispatch => {
    dispatch({
      type: types.TOKEN_SENT,
      response
    });
  };
}

export function jobOfferAccept(bool) {
  return dispatch => {
    dispatch({
      type: types.JOB_OFFER_ACCEPT,
      bool
    });
  };
}

export function updateLastActionPerormedJob(response) {
  return dispatch => {
    dispatch({
      type: types.LAST_ACTION_PERFOMED_JOB,
      response
    });
  };
}

export function saveCustomerApproval(response) {
  return dispatch => {
    dispatch({
      type: types.SAVE_CUSTOMER_APPROVAL,
      response
    });
  };
}

export function saveCustomerSignature(response) {
  return dispatch => {
    dispatch({
      type: types.SAVE_CUSTOMER_SIGNATURE,
      response
    });
  };
}

export function saveDealerSignature(response) {
  return dispatch => {
    dispatch({
      type: types.SAVE_DEALER_SIGNATURE,
      response
    });
  };
}

export async function sendIndemnityAns(jobID, data) {
  try {
    const response = await network.POST(postIndemnity(jobID), data);
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export async function sendSignature(data) {
  try {
    const response = await network.POST(postSign(), data);
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export async function skipSign(data) {
  try {
    const response = await network.PUT(skipUserSign(), data);
    return response;
  } catch (error) {
    if (!error.response) return undefined;
    else {
      return error.response;
    }
  }
}

export function clearIndemnityStore() {
  return dispatch => {
    dispatch({
      type: types.CLEAR_INDEMNITY_STORE
    });
  };
}

export function saveDamageFound(response) {
  return dispatch => {
    dispatch({
      type: types.SAVE_DAMAGE_FOUND,
      response
    });
  };
}
export function indemnityReferralComplete(response) {
  return dispatch => {
    dispatch({
      type: types.INDEMNITY_REFERRAL_FORM_COMPLETE,
      response
    });
  };
}
export function indemnityReferralSuccess(response) {
  return dispatch => {
    dispatch({
      type: types.INDEMNITY_REFERRAL_FORM_DATA,
      response
    });
  };
}
export function clearCustomerApprovalAns() {
  return dispatch => {
    dispatch({
      type: types.CLEAR_CUSTOMER_APPROVAL_ANS
    });
  };
}
