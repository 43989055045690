// import React from "react";
// import LOADER_IMG from "pr-utility/images/Spinner.svg";

// function Loader(props) {
//   if (props.error) {
//     return <div className='test123'>Error! <button onClick={ props.retry }>Retry</button></div>;
//   } else if (props.timedOut) {
//     return <div className='test123'>Taking a long time... <button onClick={ props.retry }>Retry</button></div>;
//   } else if (props.pastDelay) {
//     return <div className='test123'>Loading...</div>;
//   } else {
//     return null;
//   }
// }
// export default Loader;

import React from "react";
import LOADER_IMG from "pr-utility/images/Spinner.svg";
import LOADER_IMG_US from "pr-utility/images/Spinner_US.gif";

const Loader = props => {
  let title = "";
  if (props.title) {
    title = props.title;
  }
  return (
    <div className={`loaderbg loaderbgUS ${props.loaded ? "hide" : "show"}`}>
      <div className="loader_content">
        <div className="loaderText">{title}</div>
        <div className={"loaderimg loaderimgUS"}>
          <img src={LOADER_IMG_US} title="" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
