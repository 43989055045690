import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { TICK_IMG } from "pr-utility/images/index";
import * as actions from "./Actions";
import InfiniteScroll from "react-infinite-scroll-component";
import AlertMessage from "../CommonComponent/AlertMessage";
import { FormattedMessage } from "react-intl";
import Header from "../CommonComponent/Header";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
import notification from "pr-utility/utils/notification";
import { postType } from "pr-utility/utils/postMessage";
import { LogAllEvent, EventId } from "pr-utility/app-events/app-event-logger";
class SelectEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEquipmentId: null,
      equipmentUid: null,
      equipmentList: [],
      showAlert: false,
      alertData: {},
      noDataBlock: false,
      limit: Math.ceil((window.innerHeight - 103) / 63 + 3),
      offset: 0,
      hasMoreData: true,
      from: this.props.location.state
        ? this.props.location.state.from || ""
        : ""
    };

    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    if (this.state.from) {
      this.props.history.push("/home");
    } else {
      this.props.history.push("/select-truck");
    }
  }

  selectEquipment = truck => {
    if (
      truck &&
      truck.vehicleId &&
      Number(truck.vehicleId) !=
        Number(this.props.state.Truck.selectedTruck.id)
    ) {
      let alertData = {
        header: <FormattedMessage id="profile.select_equipment_busy" />,
        body: `${
          truck.name
        } is currently busy. Do you want to select this equipment anyway?`,
        rightText: <FormattedMessage id="profile.select_equipment_select" />,
        leftText: <FormattedMessage id="profile.select_equipment_cancel" />,
        leftOnClick: () => {
          this.setState({ showAlert: false });
        },
        rightOnClick: () => {
          this.setState({ selectedEquipmentId: truck.id,equipmentUid:truck.uKey });
          this.props.dispatch(actions.selectEquipment(truck));
        
          this.vehicleEqipmentAssignmnet(() => {
            this.chooseNextAction();
          }, truck.uKey); 
        }
      };
      this.setState({ showAlert: true, alertData: alertData });
    } else {
      this.setState({ selectedEquipmentId: truck.id,equipmentUid:truck.uKey });
      this.props.dispatch(actions.selectEquipment(truck));
      
      this.vehicleEqipmentAssignmnet(() => {
        this.chooseNextAction();
      }, truck.uKey);
    }
  };

  vehicleEqipmentAssignmnet = async (callback, uKey) => {
    this.props.dispatch(actions.isLoading(true));
    var data = {
      vehicleId: this.props.state.Truck.selectedTruck.id
    };
    const response = await actions.vehicleEquipmentAssignmentAction(
      uKey, 
      data
    );
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      if (response.data.data.length) {
        callback();
      } else {
        this.showAlertModal(false, {
          error: "An error has occurred."
        });
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.vehicleEqipmentAssignmnet, [
        callback,
        uKey
      ]);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  chooseNextAction = () => {
    if (process.env.REACT_APP_COUNTRY === 'AU') {
      this.props.history.push({
        pathname: "/pre-trip",
        state: {
          history: "select-equipment",
          from: this.state.from
        }
      });
    } 
  }

  TechnicianCheckIn = async callback => {
    LogAllEvent(
      "",
      EventId.DRIVER_CHECKIN,
      this.props.state.Authorization.authorization.entityUid,
      this.props.state.Authorization.authorization.entityType
    );
    let data = {
      location: {
        latitude: this.props.state.Location.latitude || -27.46794,
        longitude: this.props.state.Location.longitude || 153.02809,
        street: this.props.state.Location.street,
        city: this.props.state.Location.city,
        state: this.props.state.Location.state,
        zipCode: this.props.state.Location.zip
      },
      truckInfo: {},
      driverInfo: {
        uid: this.props.state.Authorization.authorization.entityUid,
        checkInVehicle: true
      }
    };
    // /*eslint-disable no-unused-expressions*/
    // if(this.props.state.Truck && this.props.state.Truck.selectedTruck && this.props.state.Truck.selectedTruck.status === 0) {
    //   data.driverInfo.checkInVehicle = true;
    // }

    this.props.dispatch(actions.isLoading(true));
    const response = await actions.TechnicianCheckIn(data);
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      if (response.data.data[0].driverInfo) {
        let driverData = this.props.state.DriverData.driverDetail;
        driverData.driverInfo.status = 1;
        driverData.driverInfo.reachable = "AVAILABLE";
        await this.props.dispatch(actions.DriverDetailSuccess(driverData));
        callback();
      } else {
        this.showAlertModal(false, {
          error: "An error has occurred."
        });
        sendErrorLog({
          data: {
            error: "An error has occurred on TechnicianCheckIn(pretrip) detail."
          }
        });
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.TechnicianCheckIn, callback);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  showAlertModal = (netWorkError, response, callback, params) => {
    let alertData;
    if (netWorkError) {
      if (response && response.code && response.code === "ECONNABORTED") {
        alertData = {
          header: <FormattedMessage id="common.timeout_header" />,
          body: <FormattedMessage id="common.timeout_message" />,
          rightText: <FormattedMessage id="common.cancel" />,
          leftOnClick: () => {
            this.setState({ showAlert: false });
            if (params && Array.isArray(params) && params.length > 1) {
              callback(...params);
            } else if (params) {
              callback(params);
            } else {
              callback();
            }
          },
          leftText: <FormattedMessage id="common.retry" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      } else {
        alertData = {
          header: "",
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.close" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      }
    } else {
      if (response) {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: response.data ? (
            response.data.error ? (
              response.data.error
            ) : (
              <FormattedMessage id="profile.error_msg" />
            )
          ) : (
            <FormattedMessage id="profile.error_msg" />
          ),
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      } else {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      }
    }
    this.setState({ showAlert: true, alertData: alertData });
  };
  componentDidMount() {
    LogAllEvent(
      "",
      EventId.ON_SELECT_EQUIPMENT_SCREEN,
      this.props.state.Authorization.authorization.entityUid,
      this.props.state.Authorization.authorization.entityType
    );
    this.loadEquipments();
  }

  loadEquipments = async () => {
    await this.getEquipmentList();

    if (!this.state.from) {
      //Do not change case of any key here which we are posting to native
      let page = "select-truck";
      let data = {
        AppConfig: this.props.state.AppConfig,
        authorization: this.props.state.Authorization.authorization,
        DriverData: this.props.state.DriverData
      };
      let type = postType.APP_STATE;
      notification.sendMessageToNative(page, data, type);
    }
  };

  fetchMoreData = async () => {
    this.setState({ offset: this.state.offset + this.state.limit });
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.getEquipmentListAction(
      this.props.state.DriverData.driverDetail.driverInfo.companyId,
      this.state.limit,
      this.state.offset
    );
    if (response && Number(response.status) === 200) {
      if (response.data.data.length > 0) {
        this.setState({
          equipmentList: this.state.equipmentList.concat(response.data.data)
        });
        
        let truckAppendedData = this.state.equipmentList;
        /*truckAppendedData.sort(function(a, b) {
          var first = a.truckInfo.name.toUpperCase();
          var second = b.truckInfo.name.toUpperCase();
          return first < second ? -1 : first > second ? 1 : 0;
        });*/
        this.setState({
          equipmentList: truckAppendedData
        });
        this.setState({ hasMoreData: true });
      } else {
        this.setState({ offset: 0 });
        this.setState({ hasMoreData: false });
      }
      this.props.dispatch(actions.isLoading(false));
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.fetchMoreData);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  getEquipmentList = async () => {
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.getEquipmentListAction(
      this.props.state.DriverData.driverDetail.driverInfo.companyId,
      this.state.limit,
      this.state.offset
    );
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      let truckData = response.data.data;
      /*truckData.sort(function(a, b) {
        var first = a.truckInfo.name.toUpperCase();
        var second = b.truckInfo.name.toUpperCase();
        return first < second ? -1 : first > second ? 1 : 0;
      });*/
      this.setState({ equipmentList: truckData });
      //this.props.dispatch(actions.authDataSuccess(response.data));
      response.data.data.length > 0
        ? this.setState({ noDataBlock: false })
        : this.setState({ noDataBlock: true });
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.getEquipmentList);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };
  renderEquipmentList = equipmentList => {
    if (!this.state.noDataBlock) {
      return equipmentList.map((truck, idx) => {
        return (
          <li key={truck.id}>
            <span className="text dark" onClick={() => this.selectEquipment(truck)}>
              {truck.name || truck.id}
            </span>
            
            {this.state.selectedEquipmentId === truck.id ? (
              <span className="tick">
                <img src={TICK_IMG} title="" alt="" />
              </span>
            ) : (
              ""
            )}
          </li>
        );
      });
    } else {
      return (
        <div className="jobList">
          <div className="dataRow ">
            <div className="head">
              <FormattedMessage id="jobs_list.not_found" />
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    /*let loaded = this.props.state.AppConfig.is_loading;*/
    let wrapper_class;
    
    let page_header = <FormattedMessage id="profile.select_equipment" />;
    
    let equipmentList = this.state.equipmentList;
    /*eslint-disable no-script-url*/
    return (
      <div className={`pg bgGray ${wrapper_class}`}>
        <Header
          title={page_header}
          left={true}
          backButton={this.goBack}
        />

        {/* <Loader loaded={!loaded} /> */}
        <AlertMessage
          showAlert={this.state.showAlert}
          {...this.state.alertData}
        />
      
        { this.state.equipmentList.length && process.env.REACT_APP_COUNTRY === 'AU' && 
        
        <div className="content pb20" id="scrollableDiv">
          <h2 className="listViewTitle">
             <FormattedMessage id="profile.equipment" />
          </h2>
          <div className="trucksWrap">
          <div className="listViewBox ">
            <InfiniteScroll
              dataLength={this.state.equipmentList.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMoreData}
              scrollableTarget="scrollableDiv"
              endMessage={<p style={{ textAlign: "center" }}>
                  <b>
                    <FormattedMessage id="jobs_history.all" />{" "}
                  </b>
                </p>}
            >
              <ul className="type3"> {this.renderEquipmentList(equipmentList)}</ul>
            </InfiniteScroll>
          </div>
          </div>
        </div>}
     
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectEquipment)
);
