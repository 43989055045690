export const ON_ACTIVATION = "ON_ACTIVATION";
export const PROVIDER_VERIFICATION_SUCCESS = "PROVIDER_VERIFICATION_SUCCESS";
export const ON_GET_CONFIG_SUCESS = "ON_GET_CONFIG_SUCESS";
export const ON_GET_CANCEL_REASONS_SUCESS = "ON_GET_CANCEL_REASONS_SUCESS";
export const IS_LOADING = "IS_LOADING";
export const DRIVER_VERIFICATION_SUCCESS = "DRIVER_VERIFICATION_SUCCESS";
export const PROVIDER_PHONE_NUMBER = "PROVIDER_PHONE_NUMBER";
export const ADD_PART = "ADD_PART";
export const TOTAL_COST = "TOTAL_COST";
export const DELETE_PART = "DELETE_PART";
export const ON_GET_CONFIG_KEYS_SUCCESS = "ON_GET_CONFIG_KEYS_SUCCESS";
export const JOB_ID = "JOB_ID";
export const ON_GET_DECLINED_JOB_SUCESS = "ON_GET_DECLINED_JOB_SUCESS";
export const ON_GET_ENTITLEMENTS_SUCESS = "ON_GET_ENTITLEMENTS_SUCESS";
export const ON_ADD_ACTIVE_JOB_LIST = "ON_ADD_ACTIVE_JOB_LIST";
export const ON_CLEAR_ACTIVE_JOB = "ON_CLEAR_ACTIVE_JOB";
export const ON_MESSAGE_RECEIVED = "ON_MESSAGE_RECEIVED";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const SAVE_CHECKIN_STATUS = "SAVE_CHECKIN_STATUS";
export const DRIVER_DETAIL_SUCCESS = "DRIVER_DETAIL_SUCCESS";
export const ACTIVATION_CODE_ENTERED = "ACTIVATION_CODE_ENTERED";
export const VEHICLE_DETAIL_SUCCESS = "VEHICLE_DETAIL_SUCCESS";
export const RESET_HOME = "RESET_HOME";
export const RESET_ADDED_PARTS = "RESET_ADDED_PARTS";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const TOKEN_SENT = "TOKEN_SENT";
export const JOB_OFFER_ACCEPT = "JOB_OFFER_ACCEPT";
export const SET_EXPIRATION_TIME = "SET_EXPIRATION_TIME";
export const LAST_ACTION_PERFOMED_JOB = "LAST_ACTION_PERFOMED_JOB";
export const CANCEL_REASON = "CANCEL_REASON";
export const ADD_ONE_NOTIFICATION = "ADD_ONE_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const DRIVER_FLOW_CHANGE = "DRIVER_FLOW_CHANGE";
export const INDEMNITY_SUCCESS = "INDEMNITY_SUCCESS";
export const SAVE_CUSTOMER_APPROVAL = "SAVE_CUSTOMER_APPROVAL";
export const SAVE_CUSTOMER_SIGNATURE = "SAVE_CUSTOMER_SIGNATURE";
export const SAVE_DEALER_SIGNATURE = "SAVE_DEALER_SIGNATURE";
export const CLEAR_INDEMNITY_STORE = "CLEAR_INDEMNITY_STORE";
export const SAVE_DAMAGE_FOUND = "SAVE_DAMAGE_FOUND";
export const ADD_ONE_DISPATCH_CHAT_NOTIFICATION =
  "ADD_ONE_DISPATCH_CHAT_NOTIFICATION";
export const CLEAR_DISPATCH_CHAT_NOTIFICATION =
  "CLEAR_DISPATCH_CHAT_NOTIFICATION";
export const SAVE_DISPATCH_CHAT_NOTIFICATION =
  "SAVE_DISPATCH_CHAT_NOTIFICATION";
export const INDEMNITY_REFERRAL_FORM_COMPLETE =
  "INDEMNITY_REFERRAL_FORM_COMPLETE";
export const INDEMNITY_REFERRAL_FORM_DATA = "INDEMNITY_REFERRAL_FORM_DATA";
export const CLEAR_CUSTOMER_APPROVAL_ANS = "CLEAR_CUSTOMER_APPROVAL_ANS";
