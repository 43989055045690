var notification = {
  postMessage: function(data) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
  },
  log: function(data) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
  },
  sendMessageToNative: function(page, data, type) {
    let appState = {
      page: page
    };
    let userData = {
      type: type,
      dto: {
        data: {
          appState: appState,
          ...data
        },
        error: false,
        errorMessage: ""
      }
    };
    notification.postMessage(userData);
  }
};

export default notification;
