import * as types from "../../actionTypes";
import {
  driverManagement,
  getAllService,
  driverPhoto,
  updateTechnicianInfo
} from "pr-utility/utils/APISignature/ProviderVerificationAPI";
import * as network from "pr-utility/utils/network";
import { jobCancelReason } from "pr-utility/utils/APISignature/Home";
import notification from "pr-utility/utils/notification";
import {
  getEntityDetail,
  uploadPhoto
} from "pr-utility/utils/APISignature/CheckInAPI";

export async function driverVerify(phoneNumber, providerNumber, data) {
  try {
    const response = await network.POST(
      driverManagement(phoneNumber, providerNumber),
      data
    );
    let successData = {
      type: "success_info",
      isjobdto: false,
      params: data,
      dto: {
        data: response,
        error: false
      }
    };
    notification.log(successData);
    return response;
  } catch (error) {
    if (!error.response) return error ;
    else {
      let errorData = {
        type: "error_info",
        isjobdto: false,
        params: data,
        dto: {
          data: error.response,
          error: true
        }
      };
      notification.log(errorData);
      return error.response;
    }
  }
}

export function driverVerificationSuccess(response) {
  return dispatch => {
    dispatch({
      type: types.DRIVER_VERIFICATION_SUCCESS,
      response
    });
  };
}

export async function getAllServiceList(provider, driver) {
  try {
    const response = await network.GET(getAllService(provider, driver));
    return response;
  } catch (error) {
    if (!error.response) return error ;
    else {
      return error.response;
    }
  }
}
export async function getCancelReason(driverId) {
  try {
    const response = network.GET(jobCancelReason(driverId));
    return response;
  } catch (error) {
    if (!error.response) return error ;
    else {
      return error.response;
    }
  }
}
export function cancelReasonSuccess(response) {
  return dispatch => {
    dispatch({
      type: types.CANCEL_REASON,
      response
    });
  };
}

export function getAllServiceListSuccess(response) {
  return dispatch => {
    dispatch({
      type: types.ALL_SERVICE_LIST_SUCCESS,
      response
    });
  };
}

export async function uploadDriverPhoto(Uid, data) {
  try {
    const response = await network.multipartPost(uploadPhoto(Uid), data);
    return response;
  } catch (error) {
    if (!error.response) return error ;
    else {
      return error.response;
    }
  }
}

export async function getDriverPhoto(providerId, driverId, data) {
  try {
    const response = await network.GET(driverPhoto(providerId, driverId));
    return response;
  } catch (error) {
    if (!error.response) return error ;
    else {
      return error.response;
    }
  }
}

export function isLoading(bool) {
  return dispatch => {
    dispatch({
      type: types.IS_LOADING,
      bool
    });
  };
}

export async function getEntityDetailAction(uid) {
  try {
    const response = await network.GET(getEntityDetail(uid));
    return response;
  } catch (error) {
    if (!error.response) return error ;
    else {
      return error.response;
    }
  }
}

export async function updateDriverInfo(uid, data) {
  try {
    const response = await network.PUT(
        updateTechnicianInfo(uid),
        data
    );
    return response;
  } catch (error) {
    if (!error.response) return error ;
    else {
      return error.response;
    }
  }
}


export function updateDriverDetailSuccess(response) {
  return dispatch => {
    dispatch({
      type: types.DRIVER_DETAIL_SUCCESS,
      response
    });
  };
}