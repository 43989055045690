module.exports = {
  setConfigs(baseConfigs) {
    this.port = baseConfigs.port;
    this.host = `http://${baseConfigs.host}:${baseConfigs.port}`;
    this.client = {
      publishKey: "",
      subscribeKey: baseConfigs.pubnub.subscribeKey,
      ssl: false,
      keepAlive:true,
      listenToBrowserNetworkEvents:false
    };
  }
};
