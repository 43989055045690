import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  authorization: null,
  initAppData: null,
  volumeLevel: null,
  expirationTime: null
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ON_AUTH_DATA_SUCCESS: {
      return {
        ...state,
        authorization: action.response,
        expirationTime: new Date().getTime() + action.response.expiresIn * 1000
      };
    }

    case types.INIT_APP_DATA: {
      return {
        ...state,
        initAppData: action.response
      };
    }

    case types.UPDATE_VOLUME_LEVEL: {
      return {
        ...state,
        volumeLevel: action.response
      };
    }
    case types.RESET_AUTHORIZATION: {
      return {
        ...state,
        authorization: null,
        expirationTime: null
      };
    }

    default:
      return state;
  }
}
