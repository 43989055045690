import React from "react";
import * as Sentry from "@sentry/browser";
import ReportFeedback from "./ReportFeedback"
import client from "pr-utility/config/client";

class SentryErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      if(process.env.REACT_APP_WEB_APP_VERSION){
        scope.setTag("Webapp-version", process.env.REACT_APP_WEB_APP_VERSION);
      }
      if(client){
        scope.setTag("App Version", client.APP_VESION);
        scope.setTag("Dev Environment", client.ENVIROMENT_STRING);
      }
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return <ReportFeedback />;
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default SentryErrorBoundary;
