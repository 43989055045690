import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  selectedEquipment: null
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SELECT_EQUIPEMENT: {
      return {
        ...state,
        selectedEquipment: action.response
      };
    }
    case types.RESET_EQUIPEMENT_DATA: {
      return {
        ...initialState
      };
    }

    default:
      return state;
  }
}
