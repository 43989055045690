import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  indemnity: null,
  customerApprovalAns: [],
  customerSignature: "",
  dealerSignature: "",
  damageFound: null,
  indemnityReferral: null,
  jobNumber: null,
  type: null //PRE or POST
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.INDEMNITY_SUCCESS: {
      return {
        ...state,
        indemnity: action.response
      };
    }
    case types.SAVE_CUSTOMER_APPROVAL: {
      return {
        ...state,
        customerApprovalAns: action.response
      };
    }
    case types.SAVE_CUSTOMER_SIGNATURE: {
      return {
        ...state,
        customerSignature: action.response
      };
    }
    case types.SAVE_DEALER_SIGNATURE: {
      return {
        ...state,
        dealerSignature: action.response
      };
    }
    case types.CLEAR_INDEMNITY_STORE: {
      return {
        indemnity: null,
        customerApprovalAns: [],
        customerSignature: "",
        dealerSignature: "",
        damageFound: null,
        indemnityReferral: null,
        jobNumber: null,
        type: null //PRE or POST
      };
    }
    case types.SAVE_DAMAGE_FOUND: {
      return {
        ...state,
        ...action.response
      };
    }
    case types.INDEMNITY_REFERRAL_FORM_COMPLETE: {
      return {
        ...state,
        indemnityReferral: action.response,
        type: action.response.type
      };
    }
    case types.INDEMNITY_REFERRAL_FORM_DATA: {
      return {
        ...state,
        ...action.response
      };
    }
    case types.CLEAR_CUSTOMER_APPROVAL_ANS: {
      return {
        ...state,
        customerApprovalAns: []
      };
    }
    default:
      return state;
  }
}
