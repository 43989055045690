import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  configs: null,
  config_keys: null,
  cancel_reasons: null,
  is_loading: false,
  shell: true
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.IS_LOADING: {
      return {
        ...state,
        is_loading: action.bool
      };
    }
    case types.ON_GET_CONFIG_SUCESS: {
      return {
        ...state,
        configs: action.response
      };
    }
    case types.ON_GET_CANCEL_REASONS_SUCESS: {
      return {
        ...state,
        cancel_reasons: action.response
      };
    }
    case types.ON_GET_CONFIG_KEYS_SUCCESS: {
      return {
        ...state,
        config_keys: action.response
      };
    }
	case types.ON_MESSAGE_RECEIVED: {
	  return {
        ...state,
        configs: action.response.config,
        config_keys: action.response.config_keys
      };
    }
  case types.RESTORE_CONFIG:{
    return {
      ...state,
      configs: action.response.configs,
      config_keys: action.response.config_keys,
      cancel_reasons:  action.response.cancel_reasons,
      shell:  action.response.shell
    };
  }
  

    default:
      return state;
  }
}
