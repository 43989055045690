import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Header from "../CommonComponent/Header";
import { FormattedMessage } from "react-intl";
import Cleave from "cleave.js/dist/cleave-react";
import * as actions from "./Actions";
import AlertMessage from "../CommonComponent/AlertMessage";
import { Events } from "pr-utility/event-manager/event";
import { EventList } from "pr-utility/event-manager/event-list";
import { postType } from "pr-utility/utils/postMessage";
import notification from "pr-utility/utils/notification";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
import { onBlurEvent, scroll } from "pr-utility/utils/utils";
// 
import {mobileNumberFormat} from "pr-utility/utils/utils";
import client from "pr-utility/config/client";
import { LogAllEvent, EventId } from "pr-utility/app-events/app-event-logger";
class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PIN: "",
      activateEnabled: false,
      PINEntered: false,
      showAlert: false,
      alertData: {},
      PINLength: 6,
      driverInfo: {},
      driverImageState: null,
      recievedData: {},
      providerName:'',
      blocks: mobileNumberFormat[client.DEFAULT_COUNTRY_CODE].blocks,
      delimiters: mobileNumberFormat[client.DEFAULT_COUNTRY_CODE].delimiters,
      numericOnly: mobileNumberFormat[client.DEFAULT_COUNTRY_CODE].numericOnly
    };

    this.showAlertModal = this.showAlertModal.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
  }

  onPinEntered = event => {
    if (this.state.PIN === event.target.rawValue) {
      return;
    }
    this.setState({ activateEnabled: false });
    if (
      event.target.value !== "" &&
      event.target.value.length !== 0 &&
      event.target.value.length === this.state.PINLength &&
      !this.state.PINEntered &&
      this.state.PIN !== event.target.rawValue
    ) {
      this.setState({ PIN: event.target.rawValue });
      this.setState({ PINEntered: true });
      document.getElementById('cardNum').blur();
    } else {
      this.setState({ PIN: event.target.rawValue });
      this.setState({ PINEntered: false });
      this.setState({ activateEnabled: false });
    }
  };

  showAlertModal(netWorkError, response, callback, params) {
    let alertData;
    if (netWorkError) {
      if (response && response.code && response.code === "ECONNABORTED") {
        alertData = {
          header: <FormattedMessage id="common.timeout_header" />,
          body: <FormattedMessage id="common.timeout_message" />,
          rightText: <FormattedMessage id="common.cancel" />,
          leftOnClick: () => {
            this.setState({ showAlert: false });
            if (params && Array.isArray(params) && params.length > 1) {
              callback(...params);
            } else if (params) {
              callback(params);
            } else {
              callback();
            }
          },
          leftText: <FormattedMessage id="common.retry" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      } else {
        alertData = {
          header: "",
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.close" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      }
    } else {
      if (response) {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: response.data ? (
            response.data.error ? (
              response.data.error
            ) : (
              <FormattedMessage id="profile.error_msg" />
            )
          ) : (
            <FormattedMessage id="profile.error_msg" />
          ),
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      } else {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: <FormattedMessage id="profile.company_not_activated" />,
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          }
        };
      }
    }
    this.setState({ showAlert: true, alertData: alertData });
  }

  handleBack() {
    // let userData = {
    //   type: postType.NAVIGATE_BACK,
    //   dto: {
    //     data: {},
    //     error: false,
    //     errorMessage: ""
    //   }
    // };
    // notification.postMessage(userData);
    if (process.env.REACT_APP_COUNTRY === 'AU') {
      this.props.history.push("/activate-app");
    } else if(process.env.REACT_APP_COUNTRY === 'US'){
      this.props.history.push("/mobile-verification");
    }
  }

  verifyOTP = () => {
    //  this.verifyOtpAPI(this.state.OTP, () => {
    // let userData = {
    //   type: postType.USER_SESSION,
    //   dto: {
    //     data: this.props.state.Authorization,
    //     error: false,
    //     errorMessage: ""
    //   }
    // };
    // notification.postMessage(userData);

    //TO-DO check in possible from home only
    // this.TechnicianCheckIn(() => {});
    this.validatePin(this.state.PIN, () => {
      this.props.history.push("/select-truck");
    });
  };

  handleNotMe = () => {
    if(process.env.REACT_APP_COUNTRY === 'US'){
      let userData = {
        type: postType.LOGOUT,
        dto: {
          data: {},
          error: false,
          errorMessage: ""
        }
      };
      notification.postMessage(userData);
    } else {
      this.props.history.push("/activate-app");
    }
  };

  validatePin = async (PIN, callback) => {
    this.props.dispatch(actions.isLoading(true));
    const entityUid = this.props.state.Authorization.authorization.entityUid;
    const env = this.props.state.AppConfig.configs.env;
    const version = this.props.state.AppConfig.configs.version;
    const data = {
      appInfo: {
        password: PIN,
        environment: env,
        version: version,
        envId: "v4"
      }
    };
    const response = await actions.verifyPassword(entityUid, data);
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      this.setState({ activateEnabled: true });
      if (response.data.data[0].driverInfo) {
        callback(response);
      } else {
        this.showAlertModal(false, {
          error:
            "An error has occurred, Please check to see if you typed a correct verfication code and try again."
        });
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.validatePin, [PIN, callback]);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  getDriverConfig = async () => {
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.getDriverConfigAction(
      this.props.state.Authorization.authorization.entityUid
    );
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      if (response.data.data[0].appInfo) {
        this.props.dispatch(actions.driverConfigSuccess(response.data.data[0]));
      } else {
        this.showAlertModal(false, {
          error: "An error has occurred."
        });
        sendErrorLog({
          data: {
            error: "An error has occurred on getDriverConfig(user) detail."
          }
        });
      }
      //this.props.dispatch(actions.authDataSuccess(response.data));
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.getDriverConfig);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  componentDidMount() {
    LogAllEvent(
      "",
      EventId.ON_USER_PIN,
      this.props.state.Authorization.authorization.entityUid,
      this.props.state.Authorization.authorization.entityType
    );
    this.getEntityInformation();
    Events.on(
      EventList.native.locationUpdate,
      "individual_flow",
      this.updateLocation
    );

    Events.on(
      EventList.native.add_photo,
      "individual_flow",
      this.sendEditedPhoto
    );
    if(!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)){
      //alert("is iOS");
      var element =document.getElementById("userScreen");
      element.classList.add("positionRel");
    } else {
      var userElement =document.getElementById("userWrap");
      userElement.classList.add("resetContent");
    }
  }

  getEntityInformation = async () => {
    await this.getEntityDetail();
    await this.getDriverConfig();

    //Do not change case of any key here which we are posting to native
    let page = "user";
    let data = {
      AppConfig: this.props.state.AppConfig,
      authorization: this.props.state.Authorization.authorization,
      DriverData: this.props.state.DriverData
    };
    let type = postType.APP_STATE;
    notification.sendMessageToNative(page, data, type);
  };

  sendEditedPhoto = async recievedData => {
    this.props.dispatch(actions.isLoading(true));
    this.setState({ recievedData: recievedData });

    let imageData = `data:image/jpg+xml;base64,${recievedData.data}`;
    const data = new FormData();
    let arrayValue = [];
    try {
      let binary = atob(imageData.split(",")[1]);
      for (let i = 0; i < binary.length; i++) {
        arrayValue.push(binary.charCodeAt(i));
      }

      var payload = new Blob([new Uint8Array(arrayValue)], {
        type: "image/jpg"
      });
      data.append("photo", payload);
    } catch (er) {
      sendErrorLog({
        data: {
          error: er
        }
      });
    }

    var response = await actions.uploadDriverPhoto(
      this.props.state.Authorization.authorization.entityUid,
      data
    );

    if (response && Number(response.status) === 200) {
      this.props.dispatch(actions.isLoading(false));
    } else if (response.code === "ECONNABORTED") {
      this.showAlertModal(true, response, this.sendEditedPhoto, recievedData);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  updateLocation(data) {
    this.props.dispatch(actions.updateLocationSuccess(data.dto));
  }

  getEntityDetail = async () => {
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.getEntityDetailAction(
      this.props.state.Authorization.authorization.entityUid
    );
    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      if (response.data.data[0]) {
        this.props.dispatch(actions.isLoading(false));
        this.props.dispatch(actions.DriverDetailSuccess(response.data.data[0]));
        this.props.dispatch(
          actions.SaveCheckinStatus(response.data.data[0].driverInfo.status)
        );
        this.setState({
          driverInfo: response.data.data[0].driverInfo,
          providerName: response.data.data[0].provider.name,
          driverImageState: `${
            response.data.data[0].driverInfo.picture
          }?d=${new Date().getTime()}`
        });
      } else {
        this.showAlertModal(false, {
          error: "An error has occurred."
        });
        sendErrorLog({
          data: {
            error: "An error has occurred on getEntityDetail(user) detail."
          }
        });
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.getEntityDetail);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };
  addPhotoClick = () => {
    let obj = {
      type: postType.ADD_PHOTO,
      dto: {
        data: null,
        error: false,
        errorMessage: "",
        cameraOpen: true
      }
    };
    notification.postMessage(obj);
  };

  render() {
    let driverImage = this.state.recievedData.data || "";
    let driverImageState = this.state.driverImageState;
    /*let loaded = this.props.state.AppConfig.is_loading;*/
    let page_header = <FormattedMessage id="user.header" />;
    let continue_button = <FormattedMessage id="user.continue_button" />;
    let wrapper_class,animateClass,header;
    let pinLabel = this.state.driverInfo.hasPassword ? (
      <FormattedMessage id="user.label_enter_pin" />
    ) : (
      <FormattedMessage id="user.label_create_pin" />
    );

    if(process.env.REACT_APP_COUNTRY === 'US'){
      wrapper_class = 'parent_us';
      let testActivation=['610890','585668','775781','001761','156963'];
      let activationCode=this.props.state.Authorization.authorization.code;
      if(activationCode && testActivation.indexOf(activationCode.substr(0, 6))>=0){
      header=<Header title={page_header} />;
      } else {
      header=<Header title={page_header} left="true" backButton={this.handleBack} />;
      }
    } else {
      header=<Header title={page_header} left="true" backButton={this.handleBack} />;
    }
    
    /*eslint-disable no-script-url*/
    return (
      <div className={`pg bgGray ${wrapper_class}`} id="userScreen">
        {header}
        {/* <Loader loaded={!loaded} /> */}
        <AlertMessage
          showAlert={this.state.showAlert}
          {...this.state.alertData}
        />
        <div className="content" id="userWrap">
          <div className="">
            <div className="clearfix" />
            <div className="gap10" />
            <h2 className="pgTitle mt5 mb0 text-center">
              {this.state.driverInfo.firstName
                ? this.state.driverInfo.firstName +
                  " " +
                  this.state.driverInfo.lastName
                : ""}
              &nbsp;
            </h2>
            {(process.env.REACT_APP_COUNTRY === 'US' && this.state.driverInfo.phoneNumber)
              ? <div className="text-center">
                  <Cleave
                    value={this.state.driverInfo.phoneNumber}
                    options={{
                      blocks: this.state.blocks,
                      delimiters: this.state.delimiters,
                      numericOnly: this.state.numericOnly
                    }}
                    className="txtBox userDetailstext fontwtb driverPhone"
                    required
                    disabled={true}
                  />
                  <p className="userDetailstext">{this.state.providerName}</p>
                </div>
              :''
            }
            
            <div className="profile_box mt15">
              <div className="profilePic">
                {driverImage && (
                  <div className="img">
                    <div
                      className="imgInner active"
                      style={{
                        background:
                          "url(data:image/jpg+xml;base64," + driverImage + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%"
                      }}
                    />
                  </div>
                )}
                {driverImageState && !driverImage && (
                  <div className="img">
                    <div
                      className="imgInner active"
                      style={{
                        background: "url(" + driverImageState + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%"
                      }}
                    />
                  </div>
                )}
                {!driverImageState && !driverImage && <div className="img" />}
                <a
                  href="javascript:void(0)"
                  className="lnk"
                  onClick={() => this.addPhotoClick()}
                >
                {process.env.REACT_APP_COUNTRY === 'AU' ?
                <FormattedMessage id="profile.add_photo" />
                : ((driverImageState && !driverImage &&  driverImageState.indexOf('http')==-1) ? <FormattedMessage id="profile.add_photo" /> : <FormattedMessage id="profile.edit_photo" />)
                }
              
                  
                </a>
              </div>
            </div>
            <h2 className="listViewTitle fontSize18">{pinLabel}</h2>
            <div className="listViewBox">
              <ul className="type1">
                <li>
                  <span className="text">
                    <Cleave
                      value={this.state.PIN}
                      className="txtBox"
                      id="cardNum"
                      placeholder="PIN"
                      options={{
                        blocks: [6],
                        delimiter: "",
                        numericOnly: false
                      }}
                      type="text"
                      autoComplete="off"
                      maxLength={this.state.PINLength}
                      onChange={this.onPinEntered}
                      onFocus={()=>scroll('.content')}
                      onClick={()=>scroll('.content')}
                      onBlur={onBlurEvent}
                      required
                    />
                  </span>
                  <span className="arrow d-none">
                    <i className="icon ion-ios-arrow-right" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {process.env.REACT_APP_COUNTRY === 'AU' && (
            <div className="text-center">
              <a
                href="javascript:void(0)"
                className="lnk mb25"
                onClick={this.handleNotMe}
              >
                <FormattedMessage id="user.label_not_me" />
              </a>
            </div>)
          }
        </div>
        <footer className="bottom_fix bgGray">
          <div className="container clearfix pl-0 pr-0 text-center">
            <a
              href="javascript:void(0)"
              className={"btn mb0 " + (this.state.PINEntered ? "" : "disabled")}
              onClick={this.verifyOTP}
            >
              {continue_button}
            </a>
          </div>
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(User)
);
