/*global google,jQuery,$*/
import { MAP_PIN } from "./imageUtils";
import sortBy from "lodash/sortBy";
import { getSmallServiceImage } from "./getServiceImage";
import ApiConfigs from "../config/client";

export const DEFAULT_ZOOM_LEVEL = 16;
export const DEFAULT_SEARCH_COUNTRY = { country: "us" };
export const TYPE_RESTRICTION = ["address"];

export function initMap(zoomLavel) {
  window.map = new window.google.maps.Map(
    document.getElementById("mapContainer"),
    {
      zoom: zoomLavel || DEFAULT_ZOOM_LEVEL,
      zoomControl: false,
      center: ApiConfigs.DEFAULT_MAP_CENTER(),
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      clickableIcons:false
    }
  );

  var remove_poi = [
    {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
        { 
          "visibility": "off" 
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];

  window.map.setOptions({ styles: remove_poi, maxZoom: 16 });

  window.markers = [];
  window.vehicleMarkers = [];
  window.jobsMarker = [];

  window.google.maps.event.addListener(window.map, "idle", function() {
    window.google.maps.event.trigger(window.map, "resize");
  });
}

export function getCurrentLocation(
  delegate,
  successCallback,
  errorCallback,
  options
) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      function(locationData) {
        if (
          locationData &&
          locationData.coords.latitude &&
          locationData.coords.longitude
        )
          successCallback.call(delegate, locationData);
        else alert("Unable to get current location. Please try again");
      },
      function(errorResponse) {
        if (errorCallback) errorCallback.call(delegate, errorResponse);
      },
      options
    );
  } else {
    // fallback: no native support
    alert("Geolocation API is not supported in this Browser.");
  }
}

export function addAssignedJobsMarkerToMap(jobs) {
  removeJobsMarkers();
  window.map.setZoom(DEFAULT_ZOOM_LEVEL);
  jobs.forEach(job => {
    var pos = {
      latitude: job.location.latitude,
      longitude: job.location.longitude
    };
    let marker = new google.maps.Marker({
      position: new google.maps.LatLng(pos.latitude, pos.longitude),
      map: window.map,
      icon: getSmallServiceImage(job.service.serviceId),
      title: "Job title"
    });
    var address = job.location.address
      ? job.location.address
      : job.location.street;
    var infoContent =
      "<div id='content' class='info_box'><div id='siteNotice'></div><b id='serviceHeading' class='serviceHeading'>" +
      "<span class='vip'>VIP</span> <span class='title'>" +
      job.service.name +
      "</span>" +
      "</b><div id='bodyContent'><p>" +
      "<p class='servicename'>" +
      job.service.contactName +
      "</p>" +
      "</p><p class='address'>" +
      address +
      "<span class='miles'>2.14 mi</span>" +
      "</p>" +
      "<a href='javascript:void(0)' class='btnsee'>See Job Details</a>" +
      "</div></div>";
    var infowindow = new google.maps.InfoWindow({
      content: infoContent
    });

    marker.addListener("click", function() {
      infowindow.open(window.map, marker);
      setTimeout(() => {
        $.each($(".gm-style-iw").parent(), function(key, item) {
          $(item).addClass("main_info_window");
          $($(item.children[0]).children()[1]).addClass("cust_class");
          $($(item.children[0]).children()[3]).addClass("whiteBg");
          $($(item.children[0]).children()[0]).addClass("boxarrow");
          $($(item.children[0]).children()[2]).addClass("boxarrowbehind");
          $(item.children[2]).addClass("closeBtn");
        });
      }, 1);
    });
    window.jobsMarker.push(marker);
  });
  fitToMarkers([...window.jobsMarker, ...window.markers]);
}

export function removeAllMarkers(map) {
  if(window.markers){
    for (var i = 0; i < window.markers.length; i++) {
      window.markers[i].setMap(null);
    }
  }  
  window.markers = [];
  if (window.DriverMarker) {
    for (var j = 0; j < window.DriverMarker.length; j++) {
      window.DriverMarker[j].setMap(map);
    }
  }
}

export function removeDriverMarkers() {
  if (window.DriverMarker) {
    for (var j = 0; j < window.DriverMarker.length; j++) {
      window.DriverMarker[j].setMap(null);
    }
    window.DriverMarker = [];
  }
}

export function removeVehicleMarkers() {
  if (window.vehicleMarkers) {
    for (var j = 0; j < window.vehicleMarkers.length; j++) {
      window.vehicleMarkers[j].setMap(null);
    }
    window.vehicleMarkers = [];
  }
}

export function removeJobsMarkers() {
  if (window.jobsMarker) {
    for (var j = 0; j < window.jobsMarker.length; j++) {
      window.jobsMarker[j].setMap(null);
    }
    window.jobsMarker = [];
  }
}

export function drawPolyLine(cordinates, color) {
  if (window.polylinePath) {
    window.polylinePath.setMap(null);
  }
  window.polylinePath = null;
  window.polylinePath = new google.maps.Polyline({
    path: cordinates,
    geodesic: true,
    strokeColor: color,
    strokeOpacity: 2.0,
    strokeWeight: 4,
    map: window.map
  });
}

export function fitToMarkers(markers) {
  var bounds, length, __lat, __lng, i;
  bounds = new google.maps.LatLngBounds();
  length = markers.length;
  for (i = 0; i < length; i++) {
    if (markers[i]) {
      bounds.extend(markers[i].getPosition());
      __lat = markers[i].position.lat();
      __lng = markers[i].position.lng();
      if (__lat && __lng) {
        if (window.map) {
          window.map.fitBounds(bounds, {
            bottom: 124,
            top: 25,
            left: 5,
            right: 5
          });
          // window.map.panToBounds(bounds, {
          //   bottom: 124,
          //   top: 25,
          //   left: 5,
          //   right: 5
          // });
        }
      }
    }
  }
  if (length === 1) {
    window.map.setZoom(DEFAULT_ZOOM_LEVEL);
  }
}

export function reverseGeocode(location) {
  return new Promise((resolve, reject) => {
    var latlng = new google.maps.LatLng(location.latitude, location.longitude);
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng }, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          let result0 = results[0];
          let address = formatAddress(results[0]);
          let place = {
            location: {
              latitude: result0.geometry.location.lat(),
              longitude: result0.geometry.location.lng()
            },
            placeID: result0.place_id,
            address: address
          };
          resolve(place);
        } else {
          reject({ NO_RESULT_FOUND: "No results found", location: location });
        }
      } else {
        var error = {
          ZERO_RESULTS: "Could not find address",
          location: location,
          status: google.maps.GeocoderStatus
        };
        reject(error);
      }
    });
  });
}

export function findNearestRoute(response) {
  var index = 0,
    i;

  if (response.routes.length > 1) {
    let sortedArray = sortBy(response.routes, [
      function(o) {
        return o.legs[0].distance.value;
      }
    ]);
    for (i = 0; i < response.routes.length; i++) {
      if (
        sortedArray[0].legs[0].distance.value ===
        response.routes[i].legs[0].distance.value
      ) {
        index = i;
      }
    }
  }
  return index;
}
export function formatAddress(data) {
  var streetNumber,
    route = "",
    neighborhood,
    stateName,
    stateCode,
    cityName,
    postalCode;
  var addComp = data.address_components;
  for (var x = 0; x < addComp.length; x++) {
    // premise
    for (var y = 0; y < addComp[x].types.length; y++) {
      // if (addComp[x].types[y] === 'premise') {
      //     route = addComp[x].long_name;
      // }else
      if (addComp[x].types[y] === "street_number") {
        streetNumber = addComp[x].long_name;
      } else if (addComp[x].types[y] === "route") {
        route = addComp[x].long_name;
      } else if (addComp[x].types[y] === "sublocality") {
        if (route === "") {
          route = addComp[x].long_name;
        }
      } else if (addComp[x].types[y] === "neighborhood") {
        neighborhood = addComp[x].long_name;
      } else if (addComp[x].types[y] === "administrative_area_level_1") {
        stateName = addComp[x].long_name;
        stateCode = addComp[x].short_name;
      } else if (addComp[x].types[y] === "locality") {
        cityName = addComp[x].long_name;
      } else if (addComp[x].types[y] === "postal_code") {
        postalCode = addComp[x].long_name;
      }
    }
  }
  var currFullAdd = "";
  var FullAdd = "";
  if (streetNumber) {
    currFullAdd = streetNumber + " ";
    FullAdd = streetNumber + " ";
  }
  if (route) {
    currFullAdd += route + " ";
    FullAdd += route + ", ";
  }
  if (neighborhood) {
    currFullAdd += neighborhood + " ";
    FullAdd += neighborhood + ", ";
  }
  var currState = stateName;
  var currCity = cityName;
  var currPostCode = postalCode;
  var currLocObj = {
    fullAddress: currFullAdd,
    state: currState,
    city: currCity,
    postalCode: currPostCode,
    streetNumber: streetNumber,
    street: route,
    stateCode: stateCode
  };
  var adressUpdated = FullAdd;
  if (cityName) {
    adressUpdated += cityName + ", ";
  }
  if (stateName) {
    var length = adressUpdated.length;
    var lastElement = adressUpdated.charAt(length - 1);
    if (lastElement === "," || lastElement === " ") {
      adressUpdated += "" + stateName;
    } else {
      adressUpdated += ", " + stateName;
    }
  }
  if (postalCode) {
    adressUpdated += " " + postalCode;
  }
  currLocObj.customAddress = adressUpdated;
  if (currLocObj.fullAddress === "") {
    currLocObj.fullAddress = adressUpdated;
  }
  return currLocObj;
}

export function getETA(origin, destination, unit) {
  var service = new google.maps.DistanceMatrixService();
  return new Promise((resolve, reject) => {
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: "DRIVING",
        unitSystem:
          unit === "metric"
            ? google.maps.UnitSystem.METRIC
            : google.maps.UnitSystem.IMPERIAL // This is for AUS for IND, for US should be 'google.maps.UnitSystem.IMPERIAL'
      },
      response => {
        let res = {
          ETA:
            (response && response.rows && response.rows.length &&
              response.rows[0].elements.length &&
              response.rows[0].elements[0].duration &&
              response.rows[0].elements[0].duration.value) ||
            "",
          distance:
            (response && response.rows && response.rows.length &&
              response.rows[0].elements.length &&
              response.rows[0].elements[0].distance &&
              response.rows[0].elements[0].distance.value) ||
            ""
        };
        resolve(res);
      }
    );
  });
}
