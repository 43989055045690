import React from "react";
import { Events } from "pr-utility/event-manager/event";
import { EventList } from "pr-utility/event-manager/event-list";

class LocationUpdate extends React.Component {
   /*eslint-disable no-useless-constructor*/
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    Events.on(
      EventList.native.locationUpdate,
      "individual_flow",
      this.props.updateLocationSuccess
    );
  }
  render() {
    return <span />;
  }
}

export default LocationUpdate;
