export const ON_ACTIVATION = "ON_ACTIVATION";
export const PROVIDER_VERIFICATION_SUCCESS = "PROVIDER_VERIFICATION_SUCCESS";
export const ON_GET_CONFIG_SUCESS = "ON_GET_CONFIG_SUCESS";
export const ON_GET_CANCEL_REASONS_SUCESS = "ON_GET_CANCEL_REASONS_SUCESS";
export const IS_LOADING = "IS_LOADING";
export const DRIVER_VERIFICATION_SUCCESS = "DRIVER_VERIFICATION_SUCCESS";
export const PROVIDER_PHONE_NUMBER = "PROVIDER_PHONE_NUMBER";
export const SELECT_TRUCK = "SELECT_TRUCK";
export const SELECT_EQUIPEMENT = "SELECT_EQUIPEMENT";
export const ALL_SERVICE_LIST_SUCESS = "ALL_SERVICE_LIST_SUCESS";
export const ON_GET_CONFIG_KEYS_SUCCESS = "ON_GET_CONFIG_KEYS_SUCCESS";
export const ADD_PART = "ADD_PART";
export const DELETE_PART = "DELETE_PART";
export const ON_MESSAGE_RECEIVED = "ON_MESSAGE_RECEIVED";
export const ON_AUTH_DATA_SUCCESS = "ON_AUTH_DATA_SUCCESS";
export const ON_RESTORE_AUTH_DATA_SUCCESS = "ON_RESTORE_AUTH_DATA_SUCCESS";
export const INIT_APP_DATA = "INIT_APP_DATA";
export const UPDATE_VOLUME_LEVEL = "UPDATE_VOLUME_LEVEL";
export const RESTORE_CONFIG = "RESTORE_CONFIG";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_EXPIRATION_TIME = "SET_EXPIRATION_TIME";
export const RESET_AUTHORIZATION = "RESET_AUTHORIZATION";
export const SAVE_ACTIVATION_CODE = "SAVE_ACTIVATION_CODE";
