import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./Actions";
import client from "pr-utility/config/client";
import notification from "pr-utility/utils/notification";
import { postType } from "pr-utility/utils/postMessage";
import { disconnect } from "pr-utility/pubnub/pubnub";

class CheckAuthorization extends React.Component {
  constructor(props) {
    super(props);
    this.shouldRefreshToken = false;
  }
  shouldComponentUpdate(nextProps, nextState){
    if (
        this.props.state.Authorization &&
        this.props.state.Authorization.authorization &&
        this.props.state.Authorization.authorization.accessToken
    ) {
      let actualExpirationTime = this.props.state.Authorization.authorization
          .expirationTime;
      let securityTokenExpirationTime =
          this.props.state.Authorization.authorization.expirationTime -
          90 * 60 * 1000;
      let currentTime = new Date().getTime();
      if (currentTime > actualExpirationTime) {
          let userData = {
            type: postType.LOGOUT,
            dto: {
              data: {},
              error: false,
              errorMessage: ""
            }
          };
          notification.postMessage(userData);
          disconnect();
          if (window.location.hash.indexOf("activate-app") === -1) {
            this.props.history.push({
              pathname: "/activate-app",
              state: {
                code: this.props.state.Authorization.authorization.code
              }
            });
          }
      } else if (
          currentTime > securityTokenExpirationTime &&
          currentTime < actualExpirationTime &&
          this.shouldRefreshToken === false
      ) {
        this.shouldRefreshToken = true;
        if(this.shouldRefreshToken && client.AUTH_CODE != nextProps.state.Authorization.authorization.authToken) {
          this.initRefreshToken();
          /*new Promise((resolve) => resolve(this.refreshToken()))
              .then(() => {
                this.shouldRefreshToken = false;
              });*/
        }
      }
    }
    return true;
  }

  async initRefreshToken(){
    await this.refreshToken();
    this.shouldRefreshToken = false;
  }

  async refreshToken() {
    const response = await actions.refreshToken(
      this.props.state.Authorization.authorization
    );
    client.AUTH_TOKEN = response.accessToken;
    this.props.dispatch(await actions.refreshTokenSuccess(response));
    this.props.dispatch(actions.setExpirationTime(response));
    let authData = {
      type: postType.REFRESH_TOKEN,
      dto: {
        data: {
          Authorization: {
            ...response,
            expirationTime:
              new Date().getTime() +
              this.props.state.Authorization.authorization.expiresIn * 1000,
            code:this.props.state.Authorization.authorization.code
          }
        },
        error: false,
        errorMessage: ""
      }
    };
    notification.postMessage(authData);
  }

  render() {
    return <span />;
  }
}

function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CheckAuthorization)
);
