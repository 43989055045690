import React, { Component, Suspense } from "react";
//import Loadable from "react-loadable";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoginShell from "pr-login/src/asyncShell";
import Loader from "./Loader";

// const LoginShell = Loadable({
// 	loader: () => import("pr-login/src/asyncShell"),
// 	loading() {
// 		return null;
// 	}
// });

// const HomeShell = Loadable({
//   loader: () => import("pr-home/src/asyncShell"),
// 	loading() {
// 		return null;
// 	}
// });

// const SettingsShell = Loadable({
//   loader: () => import("pr-settings/src/asyncShell"),
//   loading: Loader
// });

const HomeShell = React.lazy(() => import("pr-home/src/asyncShell"));

const SettingsShell = React.lazy(() => import("pr-settings/src/asyncShell"));

class App extends Component {
  render() {
    let loaded = this.props.state.AppConfig.is_loading;
    return (
      <div>
        <Loader loaded={!loaded} />
        <LoginShell />
        {this.props.state &&
          this.props.state.Authorization.authorization &&
          this.props.state.Authorization.authorization.entityType && (
            <Suspense
              fallback={
                <Loader loaded={this.props.state.AppConfig.is_loading} />
              }
            >
              <HomeShell />
            </Suspense>
          )}
        {this.props.state &&
          this.props.state.Authorization.authorization &&
          this.props.state.Authorization.authorization.entityType && (
            <Suspense fallback={<div> </div>}>
              <SettingsShell />
            </Suspense>
          )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({}, dispatch)
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
